import { Button, Chip, IconNode, Image, Modal, Typography } from '@components/base';
import css from './index.module.scss';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { ListPlaceholder, SelectLabel, TextAreaLabel, TextField } from '@components/common';
import { TEAM_TAGS } from '@helpers/constants';
import { createNotes, editNotes } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { useEffect, useMemo } from 'react';
import { INotes } from '@helpers/types/order';
import { addNotesSchema } from '@helpers/yup/add-notes-order.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import Images from '@assets/images';
import { getFormattedDate, getFormattedTime } from '@helpers/utils';
import { useNavigate } from 'react-router-dom';

interface INotesCreateEditModalProps {
  open: boolean;
  handleCancel: () => void;
  data: {
    title: string;
    message: string;
    created_by: string;
    created_at: string;
  };
  orderId: string;
  openAddNoteModal: () => void;
}

const NotesDetailModal = (props: INotesCreateEditModalProps) => {
  const { open, handleCancel, data, orderId, openAddNoteModal } = props;

  const navigate = useNavigate();

  return (
    <Modal open={open} onClose={handleCancel}>
      <div className={css.modalWrapper}>
        <div className={css.modalHeader}>
          <Typography variant="h3">Notes Detail</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={handleCancel}
          />
        </div>
        <div className={css.formWrapper}>
          <section className={css.modalContent}>
            {data.title ? (
              <>
                <div className={css.notesDetail}>
                  <Typography variant="h4">{data.title}</Typography>
                  <Typography variant="body">{data.message}</Typography>
                  <div className={css.authorDetails}>
                    <div>
                      <Typography variant="label">Added By</Typography>
                      <Chip label={`${data?.created_by}`} variant="outlined" />
                    </div>
                    <div>
                      <Typography variant="label">Added On</Typography>
                      <Typography variant="p">
                        {getFormattedDate(data?.created_at)} - {getFormattedTime(data?.created_at)}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={css.buttonWrapper}>
                  <Button
                    variant="text"
                    onClick={() =>
                      navigate(`${orderId}?activeTab=Notes&activeSubTab=public&page=1`)
                    }>
                    View All
                  </Button>
                  <Button onClick={openAddNoteModal}>Add Note</Button>
                </div>
              </>
            ) : (
              <>
                <ListPlaceholder
                  title="No Notes Added Yet"
                  supportingText="When a Note is added, they will show up here."
                  buttonText="Add Note"
                  handleButtonClick={openAddNoteModal}
                />
              </>
            )}
          </section>
        </div>
      </div>
    </Modal>
  );
};

export default NotesDetailModal;
