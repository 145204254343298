import { MAX_FILE_SIZE, SUPPORTED_FORMATS } from '@helpers/constants';
import * as yup from 'yup';

const selectSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required()
});

const nullableNumberTransform = (value: any, originalValue: any) => {
  if (originalValue === '' || originalValue === null) {
    return null;
  }
  return value;
};

export const paymentDetailSchema = yup.object().shape({
  amount_paid: yup
    .number()
    .typeError('Rate should be in number')
    .moreThan(0, 'Should be greater than 0')
    .positive('Should be greater than 0 (zero)')
    .max(99999999999999, "Can't be more than 14 digits")
    .test('decimal', 'Should be upto 2 decimal places', (value) => {
      if (value && !isNaN(value)) {
        const data = value.toString().split('.');
        if (data[1]) return data[1].length <= 2 ? true : false;
        return true;
      }
      return false;
    })
    .required('Amount is required'),
  payment_date: yup.date().typeError('Select valid date').required('Payment date is required'),
  // payment_mode: selectSchema.required('Payment mode is required'),
  // payment_mode: yup.string().required('Please enter mode of payment'),
  payment_type: selectSchema.required('Payment type is required'),
  remarks: yup.string().trim().notRequired(),
  payment_proof: yup
    .mixed()
    .required('Payment proof is required')
    .test('fileSize', 'File Size is too large', (value: any) =>
      value ? value?.size <= MAX_FILE_SIZE : true
    )
    .test('fileType', 'Unsupported File Format', (value: any) =>
      value ? SUPPORTED_FORMATS.includes(value?.type) : true
    ),
  additional_charges: yup.string().notRequired().nullable()
});

export const paymentTerm = yup.object().shape({
  payment_term: yup.mixed().required('Payment term is required'),
  percentage: yup
    .number()
    .typeError('Percentage should be in number')
    .positive('Should be greater than zero')
    .min(1, 'Minimum value should be 1')
    .integer('Value must be an integer')
    .required('Percentage is required'),
  days: yup.string().transform(nullableNumberTransform).optional().nullable()
});
