import * as yup from 'yup';

const selectSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required()
});

const booleanSelectSchema = yup.object().shape({
  value: yup.boolean().required(),
  label: yup.string().required()
});

export const addRodTepConfirmationValidation = yup.object().shape({
  claim_confirmation: booleanSelectSchema
    .required('RODTEP Claimed is required')
    .test('is-true', 'RODTEP Claimed must be true', (value) => value.value != false),
  currency: selectSchema.required('Currency is required'),
  amount: yup
    .number()
    .typeError('RODTEP Amount should be in number')
    .required('RODTEP Amount is required')
});

export const addDrawbackConfirmationValidation = yup.object().shape({
  claim_confirmation: booleanSelectSchema
    .required('Drawback Claimed is required')
    .test('is-true', 'Drawback Claimed must be true', (value) => value.value != false),
  currency: selectSchema.required('Currency is required'),
  amount: yup
    .number()
    .typeError('Drawback Amount should be in number')
    .required('Drawback Amount is required')
});
