import { DataGrid, ListPlaceholder, TableSkeleton } from '@components/common';
import { useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import css from './index.module.scss';
import { IconNode, ToolTip, Typography } from '@components/base';
import { getFormattedDate } from '@helpers/utils';
import { PAYMENT_ORDER_BY } from '..';
import Images from '@assets/images';

const PaymentDone = (props: any) => {
  const { data, dataLoading, setPaymentsTabState } = props;

  const [currentStatus, setCurrentStatus] = useState(0);

  const sortDocument = (status: number) => {
    switch (status) {
      case 0:
        setPaymentsTabState((prev: any) => ({
          ...prev,
          payment_order_by: null,
          currentPageNumber: 1
        }));
        break;
      case 1:
        setPaymentsTabState((prev: any) => ({
          ...prev,
          payment_order_by: PAYMENT_ORDER_BY.ascInvoiceDate,
          currentPageNumber: 1
        }));
        break;
      case 2:
        setPaymentsTabState((prev: any) => ({
          ...prev,
          payment_order_by: PAYMENT_ORDER_BY.desInvoiceDate,
          currentPageNumber: 1
        }));
        break;
      case 3:
        setPaymentsTabState((prev: any) => ({
          ...prev,
          payment_order_by: PAYMENT_ORDER_BY.ascPaymentDate,
          currentPageNumber: 1
        }));
        break;
      case 4:
        setPaymentsTabState((prev: any) => ({
          ...prev,
          payment_order_by: PAYMENT_ORDER_BY.desPaymentDate,
          currentPageNumber: 1
        }));
        break;
      default:
        setPaymentsTabState((prev: any) => ({
          ...prev,
          payment_order_by: null,
          currentPageNumber: 1
        }));
    }
    setCurrentStatus(status);
  };

  const [columnData, rowData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Payment ID',
        accessor: 'internal_payment_info_id'
      },
      {
        Header: 'Order ID',
        accessor: 'readable_order_id'
      },
      {
        Header: 'Supplier',
        accessor: 'supplier'
      },
      {
        Header: 'Gross Amount',
        accessor: 'total_invoice_amount'
      },
      {
        Header: () => (
          <div className={css.sortTableHeader}>
            <ToolTip
              title={`Requested Amount - TDS Amount + TCS Amount + Additional Charges/Discounts`}>
              <div>
                <IconNode src={Images.alertGrey} alt="eye icon" />
              </div>
            </ToolTip>
            <span>Amount Paid</span>
          </div>
        ),
        accessor: 'total_payable_amount',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <div className={css.sortTableHeader}>
                <ToolTip
                  title={`${parseFloat(value?.adjusted_amount)} - ${parseFloat(
                    value?.tds_amount
                  )} + ${parseFloat(value?.tcs_amount)} + ${parseFloat(
                    value?.additional_charges_or_discounts
                  )}`}>
                  <div>
                    <IconNode src={Images.alertGrey} alt="eye icon" />
                  </div>
                </ToolTip>
                {value.currency} {value?.amount_to_pay}
              </div>
            </>
          );
        }
      },
      // {
      //   Header: 'Amount Paid On',
      //   accessor: 'payment_date'
      // },
      {
        Header: () => (
          <div
            className={css.sortTableHeader}
            onClick={() => {
              const newStatus = currentStatus === 0 ? 1 : currentStatus === 1 ? 2 : 1;
              sortDocument(newStatus);
            }}>
            <ToolTip
              title={
                <div>
                  <div>Invoice Number</div>
                  <div>Invoice Date</div>
                </div>
              }>
              <div>
                <IconNode src={Images.alertGrey} alt="eye icon" />
              </div>
            </ToolTip>
            <span>Invoice Details</span>
            <IconNode src={Images.sort} alt="document" width={16} height={16} />
          </div>
        ),
        accessor: 'invoice',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Typography variant="p">{value?.invoice_number}</Typography>
              <Typography variant="p">{getFormattedDate(value?.invoice_date ?? '')}</Typography>
            </>
          );
        }
      },
      {
        Header: () => (
          <div
            className={css.sortTableHeader}
            onClick={() => {
              const newStatus = currentStatus === 0 ? 3 : currentStatus === 3 ? 4 : 3;
              sortDocument(newStatus);
            }}>
            <ToolTip
              title={
                <div>
                  <div>Reference Number</div>
                  <div>Amount Paid On</div>
                </div>
              }>
              <div>
                <IconNode src={Images.alertGrey} alt="eye icon" />
              </div>
            </ToolTip>
            <span>Bank Payment Info</span>
            <IconNode src={Images.sort} alt="document" width={16} height={16} />
          </div>
        ),
        accessor: 'bank_info',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Typography variant="p">{value?.reference_number}</Typography>
              <Typography variant="p">{getFormattedDate(value?.payment_date ?? '')}</Typography>
            </>
          );
        }
      }
    ];

    const row: Array<any> = data?.length
      ? data?.map((item: any) => ({
          internal_payment_info_id: item?.internal_payment_info_id ?? '-',
          readable_order_id: item?.order_readable_ids?.length
            ? item?.order_readable_ids?.join(', ')
            : item?.order_readable_ids ?? '-',
          supplier: item?.supplier_name,
          total_invoice_amount: `${item.currency} ${item?.total_payable_amount}`,
          total_payable_amount: item,
          invoice: item,
          bank_info: item?.parent_payment_info
            ? item?.parent_payment_info?.payment_confirmation_details
            : item?.payment_confirmation_details ?? null,
          payment_date: getFormattedDate(item?.payment_confirmation_details?.payment_date ?? '')
        }))
      : [];

    return [column, row];
  }, [data]);

  return (
    <div className={css.tableWrapper}>
      {dataLoading ? (
        <TableSkeleton colsNum={7} rowsNum={10} />
      ) : rowData.length ? (
        <DataGrid columns={columnData} data={rowData} tableStyle={{ width: '100%' }} />
      ) : (
        <ListPlaceholder
          title="No Payment Completed"
          supportingText="You have not completed any payment yet."
        />
      )}
    </div>
  );
};

export default PaymentDone;
