import AccessWrapper from '@authorization/access-wrapper';
import { USER_TYPES } from '@helpers/constants';
import InternalOrderOverview from './internal';

const OrderOverview = (props: any) => {
  return (
    <>
      <InternalOrderOverview {...props} />
    </>
  );
};

export default OrderOverview;
