import AccessWrapper from '@authorization/access-wrapper';
import css from './index.module.scss';
import { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { AutoComplete, ListPlaceholder, PaginationScroller, Seo } from '@components/common';
import { Badge, Loader, Select, Tab, Tabs, ToolTip, Typography } from '@components/base';
import { useSearchParams } from 'react-router-dom';
import { Enquiry, IInternalEnquiryHomeProps, ISelect, IStringSelect } from '@helpers/types/enquiry';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InternalEnquiryCard from './components/enquiry-card/internal';
import notify from '@helpers/toastify-helper';
import { USER_TYPES } from '@helpers/constants';
import Images from '@assets/images';
import { getPermissionFromLocalStorage } from '@helpers/utils';
import {
  downloadDocuments,
  getEnquiryList,
  internalFilterOptions,
  updateInternalAssignTo,
  updateInternalEnquiryStatus
} from '@services/enquiry.service';
import { useScrollToTop } from '@helpers/hooks';
import EnquirySkeleton from '@components/common/skeletons/internal-enquiry';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileSaver = require('file-saver');
const itemLimit = 10;

const InternalEnquiry = (props: any) => {
  const { actions } = props.modulePermissions;
  const rootContainer = useRef(null);
  const topRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const initialPageNumber = useMemo(() => {
    return parseInt(searchParams.get('page') || '1', 10);
  }, [searchParams]);

  const userType = useMemo(() => {
    return getPermissionFromLocalStorage()?.user_type;
  }, []);

  const [enquiryState, setEnquiryState] = useState<IInternalEnquiryHomeProps>({
    activeTab: null,
    isLoading: false,
    currentPageNumber: initialPageNumber,
    totalEnquiryCount: 0,
    data: [],
    statuses: [],
    assigned_to_users: [],
    customer_poc_users: [],
    customer_users: [],
    filters: {
      status: null,
      assigned_to: null,
      customer_poc_id: null,
      customer_id: null
    }
  });

  const {
    activeTab,
    isLoading,
    currentPageNumber,
    totalEnquiryCount,
    data,
    customer_poc_users,
    assigned_to_users,
    statuses,
    filters,
    customer_users
  } = enquiryState;

  const statusesWithAll = useMemo(() => {
    if (!Array.isArray(statuses)) {
      return [{ label: 'All', value: null }];
    }
    return [{ label: 'All', value: null }, ...statuses];
  }, [statuses]);

  const getAllEnquiries = async (params?: any) => {
    setEnquiryState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await getEnquiryList({
      status: params?.status,
      assigned_to: params?.assigned_to?.value,
      customer_poc_users: params?.customer_poc_id?.value,
      customer_id: params?.customer_id?.value,
      page: params?.page ?? 1
    });

    if (response.success) {
      setEnquiryState((prevState) => ({
        ...prevState,
        totalEnquiryCount: response.data.count,
        data:
          userType === USER_TYPES.internal
            ? response.data.results
            : [...data, ...response.data.results],
        hasNext: !!response.data.next,
        retry: false,
        isLoading: false
      }));
    } else if (response.error) {
      setEnquiryState((prevState) => ({ ...prevState, retry: true, isLoading: false }));
      notify({
        message: response.error ?? 'Something Went Wrong, Contact Tech Team'
      });
      return;
    }
  };

  const fetchFilterOptions = async () => {
    setEnquiryState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await internalFilterOptions();
    if (response.success) {
      const modifiedStatuses = response.data.statuses.map((status: any) => {
        if (status.value === 0) {
          return {
            ...status,
            disabled: true
          };
        }
        return status;
      });

      setEnquiryState((prevState) => ({
        ...prevState,
        statuses: modifiedStatuses,
        assigned_to_users: response.data.assigned_to_users,
        customer_poc_users: response.data.customer_poc_users,
        customer_users: response.data.customers,
        isLoading: false
      }));
    } else if (response.error) {
      setEnquiryState((prevState) => ({ ...prevState, isLoading: false }));
      notify({
        message: response.error ?? 'Something Went Wrong, Contact Tech Team',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    if (userType === USER_TYPES.internal) {
      fetchFilterOptions();
    }
  }, []);
  useEffect(() => {
    const hasNonNullFilter = Object.values(filters).some((value) => value !== null);
    if (hasNonNullFilter) {
      getAllEnquiries({ ...filters, page: 1 });
      setEnquiryState((prevState) => ({ ...prevState, currentPageNumber: 1 }));
      setSearchParams({
        page: '1'
      });
    } else {
      getAllEnquiries({
        page: currentPageNumber
      });
    }
  }, [filters]);

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setEnquiryState({
      ...enquiryState,
      currentPageNumber: 1,
      activeTab: newValue,
      filters: { ...filters, status: newValue }
    });
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: '1'
    });
  };

  const handlePageChange = async (newPage: number) => {
    if (newPage === 0 || newPage > Math.ceil(totalEnquiryCount / itemLimit)) {
      return;
    }
    setEnquiryState((prev) => ({ ...prev, currentPageNumber: newPage }));
    getAllEnquiries({
      ...filters,
      page: newPage
    });
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: newPage.toString()
    });
  };

  const validatePageChange = (event: any, value: number) => {
    if (isNaN(value)) return;
    if (value === 0 || value > Math.ceil(totalEnquiryCount / itemLimit)) return;
    handlePageChange(value);
  };

  const handleDocumentZipDownload = async (enquiryId: string) => {
    setEnquiryState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await downloadDocuments(enquiryId);
    setEnquiryState((prevState) => ({ ...prevState, isLoading: false }));

    if (typeof response == 'object' && !response?.error) {
      const blob = new Blob([response], { type: 'application/zip' });
      FileSaver.saveAs(blob, `${enquiryId ?? `ORDER`}.zip`);
    } else {
      notify({
        message: response?.error ?? 'Something Went Wrong',
        severity: 'error'
      });
    }

    if (response.success) {
      notify({
        message: 'Document Downloaded Successfully',
        severity: 'success'
      });
    } else if (response.error) {
      notify({
        message: response.error ?? 'Something Went Wrong',
        severity: 'error'
      });
    }
  };

  const handleInternalAssignTo = async (userId: string, enquiryId: string) => {
    setEnquiryState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await updateInternalAssignTo(userId, enquiryId);
    if (response.success) {
      setEnquiryState((prevState) => ({
        ...prevState,
        isLoading: false,
        data: prevState.data.map((enquiry) =>
          enquiry.id === enquiryId
            ? {
                ...enquiry,
                assigned_to_info: assigned_to_users?.find((user) => user.value === userId)
                  ? {
                      user_id: userId,
                      full_name:
                        assigned_to_users?.find((user) => user.value === userId)?.label || '',
                      email: assigned_to_users?.find((user) => user.value === userId)?.email || '',
                      profile_image: ''
                    }
                  : enquiry.assigned_to_info
              }
            : enquiry
        )
      }));
      notify({
        message: 'Enquiry Assigned Successfully',
        severity: 'success'
      });
    } else if (response.error) {
      setEnquiryState((prevState) => ({ ...prevState, isLoading: false }));
      notify({
        message: response.error ?? 'Something Went Wrong',
        severity: 'error'
      });
    }
  };

  const handleInternalEnquiryStatusUpdate = async (status: number, enquiryId: string) => {
    setEnquiryState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await updateInternalEnquiryStatus(status, enquiryId);
    if (response.success) {
      setEnquiryState((prevState) => ({
        ...prevState,
        isLoading: false,
        data: prevState.data.map((enquiry) =>
          enquiry.id === enquiryId
            ? {
                ...enquiry,
                status: status,
                status_display_value: statuses?.find((s) => s.value === status)?.label || '-'
              }
            : enquiry
        )
      }));
      notify({
        message: 'Enquiry Status Updated Successfully',
        severity: 'success'
      });
    } else if (response.error) {
      setEnquiryState((prevState) => ({ ...prevState, isLoading: false }));

      notify({
        message: response.error ?? 'Something Went Wrong',
        severity: 'error'
      });
    }
  };

  useScrollToTop({ topRef, dependencyArray: [currentPageNumber] });

  if (isLoading) return <EnquirySkeleton />;

  return (
    <main ref={rootContainer} className={css.enquiryWrapper}>
      <Seo title="Enquiry" />
      <div className={css.enquiryHeaderWrapper}>
        <div className={css.enquiryHeader}>
          <Typography variant="h2">Enquires</Typography>
          <Badge>{totalEnquiryCount}</Badge>
        </div>
      </div>
      <section ref={topRef} className={css.internalEnquiry}>
        <div className={css.enquiryTabWrapper}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            {statusesWithAll?.map((status: ISelect, index: number) => (
              <Tab key={index} label={status.label} value={status.value} />
            ))}
          </Tabs>
        </div>
        <div className={css.enquiryFilterWrapper}>
          <div className={css.enquirySelectFilter}>
            <Select
              isClearable
              isSearchable
              isLoading={isLoading}
              defaultValue={null}
              options={customer_poc_users}
              placeholder="Select Customer POC"
              onChange={(e: any) => {
                setEnquiryState((prevState) => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    customer_poc_id: e
                  }
                }));
              }}
              className={css.selectWrapper}
            />
            <Select
              isClearable
              isSearchable
              isLoading={isLoading}
              defaultValue={null}
              options={assigned_to_users}
              placeholder="Assigned People"
              onChange={(e: any) => {
                setEnquiryState((prevState) => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    assigned_to: e
                  }
                }));
              }}
              className={css.selectWrapper}
            />
            <AutoComplete
              placeholder="Select Customer"
              options={customer_users}
              onInputSelection={(event: any, param: any) => {
                setEnquiryState((prevState) => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    customer_id: param
                  }
                }));
              }}
              keyOption="label"
              rootClassName={css.selectWrapper}
            />
          </div>
          <div className={css.enquiryPaginationFilter}>
            <ToolTip title="Previous Page">
              <ArrowBackIosIcon
                sx={{
                  height: '40px',
                  cursor: currentPageNumber > 1 ? 'pointer' : 'default',
                  color: currentPageNumber > 1 ? '#000000' : '#e2e2e2'
                }}
                onClick={() => validatePageChange(null, currentPageNumber - 1)}
              />
            </ToolTip>
            <Typography className={css.pageOfTotalPage}>
              {currentPageNumber} of {Math.ceil(totalEnquiryCount / itemLimit)}
            </Typography>
            <ToolTip title="Next Page">
              <ArrowForwardIosIcon
                sx={{
                  height: '40px',
                  cursor:
                    currentPageNumber < Math.ceil(totalEnquiryCount / itemLimit)
                      ? 'pointer'
                      : 'default',
                  color:
                    currentPageNumber < Math.ceil(totalEnquiryCount / itemLimit)
                      ? '#000000'
                      : '#e2e2e2'
                }}
                onClick={() => validatePageChange(null, currentPageNumber + 1)}
              />
            </ToolTip>
          </div>
        </div>

        <div className={css.internalEnquiryContainer}>
          {data.length > 0
            ? data?.map((item: Enquiry, index: number) => {
                return (
                  <InternalEnquiryCard
                    data={item}
                    key={index}
                    handleClick={handleDocumentZipDownload}
                    assignToPeople={assigned_to_users || []}
                    enquiryStatus={statuses || []}
                    handleInternalAssignTo={handleInternalAssignTo}
                    handleInternalStatus={handleInternalEnquiryStatusUpdate}
                  />
                );
              })
            : !isLoading && (
                <ListPlaceholder
                  title="No Enquiry Added Yet"
                  supportingText="When Enquiries are added they will show up here."
                  image={Images.enquiryIllustration}
                />
              )}
        </div>
      </section>
      {!isLoading && userType === USER_TYPES.internal && data.length > 0 && (
        <PaginationScroller
          variant="text"
          defaultPage={1}
          count={totalEnquiryCount}
          pageLimit={itemLimit}
          page={currentPageNumber}
          onChange={validatePageChange}
        />
      )}
    </main>
  );
};

export default InternalEnquiry;
