/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { BUSINESS_TYPE, DEFAULT_CROP_LIMIT, SCREEN_TYPE, SUPPLIER_TYPE } from './constants';
import { chipVariantType } from './types';
import { IAddressForm } from './types/address';
import { ICustomerDetailForm, ICustomerInfo } from './types/customer';
import {
  IBankDetailForm,
  IBankdetail,
  ISupplier,
  ISupplierDetailForm,
  ISupplierFilterOptions
} from './types/supplier';
import moment, { Moment } from 'moment';
import { endOfDay, format } from 'date-fns';
import { DATE_FORMAT, ADDRESS_TYPE_CONSTANTS } from '@helpers/constants';
import { getToken, parseJWT } from './auth-helper';
import SessionService from './session-helper';
import { getDocumentLink } from '@services/util.service';
import notify from './toastify-helper';
import _ from 'lodash';

export function getAddressesValue(param: IAddressForm[], same_as_office: boolean) {
  const addresses = param.map((address, index) => ({
    ...(address.address_id && { address_id: address.address_id }),
    address_type: address.address_type,
    address_line1: address.address_line1,
    address_line2: address.address_line2,
    zip_code: address.zip_code,
    city: `${address.city?.value}`,
    state: `${address.state ? address.state?.value : ''}`,
    country: `${address.country?.value}`,
    same_as_office: index === 0 ? same_as_office : false,
    address_suffix: address.address_suffix ? address.address_suffix : null
  }));
  return addresses;
}

export function getSupplierDetailFormValuesFromResponse(
  param: ISupplier,
  filterOptions?: ISupplierFilterOptions
): ISupplierDetailForm {
  let same_as_office = false;
  let officeAddress: IAddressForm = {
    address_id: '',
    address_line1: '',
    address_line2: '',
    address_type: '',
    zip_code: '',
    city: null,
    state: null,
    country: null,
    address_suffix: null
  };
  const factoryAddress: IAddressForm[] = [];
  param.addresses.forEach((address) => {
    const adr = {
      address_id: address.address_id,
      address_line1: address.address_line1,
      address_line2: address.address_line2,
      address_type: address.address_type,
      zip_code: address.zip_code,
      city: address.city_obj,
      state: address.state_obj,
      country: address.country_obj,
      address_suffix: address.address_suffix
    };
    if (address.same_as_office) same_as_office = true;
    if (address.address_type === 'OFC') {
      officeAddress = adr;
      return;
    }
    factoryAddress.push(adr);
  });
  return {
    supplier_id: param.supplier_id,
    supplier_name: param.supplier_name,
    gst_number: param.gst_number,
    supplier_email: param.supplier_email,
    supplier_website: param.supplier_website,
    supplier_phone_number: param.supplier_phone_number,
    supplier_landline: param.supplier_landline,
    supplier_type:
      filterOptions?.supplier_origin_filter_options?.find(
        (item: any) => item.value == param.supplier_type
      ) ?? null,
    business_type:
      filterOptions?.business_type_filter_options?.find(
        (item: any) => item.value == param.business_type
      ) ?? null,
    vendor_id: param.vendor_id,
    addresses: factoryAddress,
    same_as_office: same_as_office,
    pocs: param.supplier_pocs,
    type:
      filterOptions?.supplier_type_filter_options?.find((item: any) => item.value == param.type) ??
      null,
    ...officeAddress
  };
}

export function getRequestBodyForSupplierDetail(param: ISupplierDetailForm) {
  const addresses = getAddressesValue(param.addresses, param.same_as_office);
  addresses.push({
    ...(param.address_id && { address_id: param.address_id }),
    address_type: 'OFC',
    address_line1: param.address_line1,
    address_line2: param.address_line2,
    zip_code: param.zip_code,
    city: `${param.city?.value}`,
    state: `${param.state ? param.state?.value : ''}`,
    country: `${param.country?.value}`,
    same_as_office: false,
    address_suffix: param.address_suffix ? param.address_suffix : null
  });
  return {
    supplier_name: param.supplier_name,
    gst_number: param.gst_number,
    supplier_email: param.supplier_email,
    supplier_website: param.supplier_website,
    supplier_phone_number: param.supplier_phone_number,
    supplier_landline: param.supplier_landline,
    supplier_type: `${param.supplier_type?.value}`,
    business_type: `${param.business_type?.value}`,
    addresses: addresses,
    bankdetails: [],
    documents: [],
    supplier_pocs: param.pocs,
    type: param.type?.value
  };
}

export function getRequestBodyForSupplierBankDetail(param: IBankDetailForm) {
  const data = param.bankdetails.map((item) => {
    const { bank_id, ...rest } = item;
    return {
      ...rest,
      ...(bank_id && { bank_id: bank_id }),
      city: `${item.city?.value}`,
      state: `${item.state ? item.state?.value : ''}`,
      country: `${item.country?.value}`,
      address_suffix: item.address_suffix ? item.address_suffix : null
    };
  });
  return {
    bankdetails: data
  };
}

export function getBankFormValuesFromResponse(bankdetails: IBankdetail[]) {
  const bankInfo = bankdetails.map((info) => {
    const { updated_at, created_at, city, state, country, ...rest } = info;
    return {
      ...rest,
      branch_name: rest.branch_name ?? '',
      city: rest.city_obj,
      state: rest.state_obj,
      country: rest.country_obj,
      address_suffix: rest.address_suffix
    };
  });
  return bankInfo;
}

export function getCustomerFormValuesFromResponse(param: ICustomerInfo): ICustomerDetailForm {
  const business_type = BUSINESS_TYPE.find(
    (item) => item.value.toUpperCase() === param.business_type.toUpperCase()
  );
  const pocs = param.pocs;

  let same_as_office = false;
  let officeAddress: IAddressForm = {
    address_id: '',
    address_line1: '',
    address_line2: '',
    address_type: '',
    zip_code: '',
    city: null,
    state: null,
    country: null,
    address_suffix: null
  };
  const factoryAddress: IAddressForm[] = [];
  param.addresses.forEach((address) => {
    const adr = {
      address_id: address.address_id,
      address_line1: address.address_line1,
      address_line2: address.address_line2,
      address_type: address.address_type,
      zip_code: address.zip_code,
      city: address.city_obj,
      state: address.state_obj,
      country: address.country_obj,
      address_suffix: address.address_suffix
    };
    if (address.same_as_office) same_as_office = true;
    if (address.address_type === 'OFC') {
      officeAddress = adr;
      return;
    }
    factoryAddress.push(adr);
  });
  const data = {
    customer_id: param.customer_id,
    name: param.name,
    number: param.number,
    email: param.email,
    website: param.website,
    business_type: business_type!,
    customer_country: { label: param.country, value: param.country },
    remarks: param.remarks,
    same_as_office: same_as_office,
    pocs: pocs,
    addresses: factoryAddress,
    ...officeAddress
  };
  return data;
}

export function getRequestBodyForCustomerDetail(data: ICustomerDetailForm) {
  const addresses = getAddressesValue(data.addresses, data.same_as_office);
  addresses.push({
    ...(data.address_id && { address_id: data.address_id }),
    address_type: 'OFC',
    address_line1: data.address_line1,
    address_line2: data.address_line2,
    zip_code: data.zip_code,
    city: `${data.city?.value}`,
    state: `${data.state ? data.state?.value : ''}`,
    country: `${data.country?.value}`,
    same_as_office: false,
    address_suffix: data.address_suffix ? data.address_suffix : null
  });
  const pocs = data.pocs.map((item) => {
    const { uid, email, ...rest } = item;
    return {
      ...(uid && { uid: uid }),
      ...rest,
      ...(!uid && { email: email })
    };
  });
  const formData: any = {
    name: data.name,
    description: data.remarks ?? '',
    remarks: data.remarks,
    email: data.email,
    number: data.number,
    website: data.website,
    business_type: data.business_type?.value,
    // country: data.customer_country?.label,
    pocs: pocs,
    addresses: addresses,
    documents: [],
    bankdetails: []
  };
  return formData;
}

export function checkChipVariant(priority: string) {
  let chipVariant: chipVariantType = 'outlined';
  switch (priority) {
    case 'High':
      chipVariant = 'filled-red';
      break;
    case 'Low':
      chipVariant = 'filled-blue';
      break;
    case 'Medium':
      chipVariant = 'filled-blue';
      break;
    case 'Critical':
      chipVariant = 'filled';
      break;
    default:
      chipVariant = 'outlined';
  }
  return chipVariant;
}

export function isValidDate(dateString: any) {
  return !isNaN(Date.parse(dateString));
}

export function getDiffBetweenTwoDates(date1: Date, date2: Date, type: any = 'days') {
  const a = moment(date1);
  const b = moment(date2);
  return a.diff(b, type);
}

export function getFormattedDate(date: any) {
  if (date) {
    return format(endOfDay(new Date(date).getTime()), DATE_FORMAT);
  } else {
    return null;
  }
}

export function getCommaSepratedValue(value: string) {
  if (value) {
    value = Number(value).toFixed(2).toString();
    const parts = value.split('.');
    return Number(parts[0]).toLocaleString() + '.' + parts[1];
  } else {
    return null;
  }
}

export const TASK_STATUS_MAPPING = [
  {
    state: 0,
    isCompleted: null
  },
  {
    state: 1,
    isCompleted: 'False'
  },
  {
    state: 2,
    isCompleted: 'True'
  }
];

export function getFormattedTime(time: any) {
  if (time) {
    return moment(time).format('H:mm');
  } else {
    return null;
  }
}

export function getReadablePaymentTerm(data: any) {
  if (data?.length) {
    const readablePaymentTerm: any = [];
    data.forEach((item: any) => {
      readablePaymentTerm.push(
        `${item.percentage}% ${item.payment_term?.payment_term || item?.payment_term}  ${
          item.days ? `${item.days} days` : ''
        }`
      );
    });
    return readablePaymentTerm;
  } else {
    return [];
  }
}

export function generateReadablePackingDetails(data: any) {
  if (data?.length) {
    const readablePackingDetails: any = [];
    data.forEach((item: any) => {
      readablePackingDetails.push(
        `${item.no_of_package} X ${item.weight} ${item.unit?.label ?? item.unit_of_weight} ${
          item.type?.label ?? item.type_label
        }`
      );
    });
    return readablePackingDetails;
  } else {
    return [];
  }
}

export function generateQuantityFromPackingDetails(data: any, unitOfWeightToBeConverted?: any) {
  try {
    if (data?.length) {
      let quantity = 0;
      data.forEach((item: any) => {
        if (item.unit === 'MT' || item?.unit?.value === 'MT' || item?.unit_of_weight === 'MT')
          quantity += parseInt(item.no_of_package) * parseInt(item.weight) * 1000;
        else quantity += parseInt(item.no_of_package) * parseInt(item.weight);
      });
      if (
        unitOfWeightToBeConverted &&
        (unitOfWeightToBeConverted.value == 'MT' || unitOfWeightToBeConverted == 'MT')
      ) {
        quantity = quantity / 1000;
      }
      return quantity;
    } else {
      return 0;
    }
  } catch (err) {
    return 0;
  }
}

export function getCustomerCountry(customer: ICustomerInfo) {
  const customerAddress = customer?.addresses.find(
    (address) => address?.address_type === ADDRESS_TYPE_CONSTANTS.OFFICE
  );
  return customerAddress?.country ?? '-';
}

export const sanitizeValue = (
  value: string | undefined | null,
  defaultAlternateValue = '-'
): string => value || defaultAlternateValue;

export function cropValues(data: any, limit = DEFAULT_CROP_LIMIT) {
  if (data) {
    return data.length > limit ? data.substring(0, limit) + '...' : data;
  } else {
    return null;
  }
}

export const formatPortOptions = (data: string[]) =>
  data &&
  data.map((data: any) => ({
    label: `${data.name} - ${data.country_name}`,
    value: `${data.id}`
  }));

export const validateEmail = (email: string) => {
  if (!email) return false;
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const getPermissionFromLocalStorage = () => {
  const token = getToken('permissions');
  //Logout user if permission token is not present
  if (!token) {
    SessionService.clearSession();
    return;
  }
  return JSON.parse(token as any);
};

export const openDocumentSignedLink = async (slug: string) => {
  if (slug?.length) {
    const response = await getDocumentLink(slug);
    if (response?.success) {
      window.open(response.data?.url, '_blank');
    } else {
      notify({
        message: response?.error ?? 'Unable to open document',
        dismissible: true,
        severity: 'error'
      });
    }
  }
  return null;
};

export const chunkArray = (arr: any[], size: number) => {
  const chunkedArr = [];
  for (let i = 0; i < arr.length; i += size) {
    chunkedArr.push(arr.slice(i, i + size));
  }
  return chunkedArr;
};

type CallbackFunctionVariadicAnyReturn = (...args: any[]) => any;

export const executeFunctionByCondition = (
  condition: boolean,
  func: CallbackFunctionVariadicAnyReturn
) => {
  if (condition) func();
  return;
};

export const integerToWords = (num: number) => {
  if (!num) return null;
  switch (num) {
    case 1:
      return 'one';
    case 2:
      return 'two';
    case 3:
      return 'three';
    case 4:
      return 'four';
    case 5:
      return 'five';
    case 6:
      return 'six';
    case 7:
      return 'seven';
    case 8:
      return 'eight';
    case 9:
      return 'nine';
    default:
      return null;
  }
};

export function getDomainWithoutWWW() {
  let hostname = window.location.hostname;
  if (hostname.startsWith('www.')) {
    hostname = hostname.substring(4);
  }

  return hostname;
}

export const pushEventTracking = (name: string) => {
  const accessToken = getToken('access');
  const jwtData = parseJWT(accessToken);
  if (window && (window as any).dataLayer) {
    (window as any).dataLayer.push({
      event: name ?? 'NO_NAMED_EVENT',
      email: jwtData?.email ?? 'NO_EMAIL'
    });
  }
};

export const SUPPORT_EMAIL = 'support@elchemy.com';
export const LEADERSHIP_EMAIL = 'leadership@elchemy.com';

export const filterNonEmptyValues = (obj: any) => {
  const nonEmptyValues = Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      } else if (typeof value === 'string') {
        return value.trim().length > 0;
      } else if (typeof value === 'number') {
        return true; // Keep all numbers, including 0
      } else {
        return value != null; //Keep everything except null and undefined
      }
    })
  );
  return nonEmptyValues;
};

export const getValidEntriesLength = (obj: any) => {
  if (obj == null) return 0;
  return Object.values(obj).filter(
    (value) => value !== null && (typeof value !== 'string' || value.length > 0)
  ).length;
};

export const checkScreenType = () => {
  if (typeof window != undefined) {
    if (window.innerWidth > SCREEN_TYPE.desktop.maxSize) return SCREEN_TYPE.desktop.label;
    else if (window.innerWidth > SCREEN_TYPE.tablet.maxSize) return SCREEN_TYPE.tablet.label;
    else return SCREEN_TYPE.mobile.label;
  }
  return null;
};

export const parseOrderEditFlag = (data: any) => {
  let orderEditable = false;
  _.forOwn(data, (value, key) => {
    if (value) {
      orderEditable = true;
    }
  });
  return orderEditable;
};

export const paymentTermsValidation = (data: any) => {
  let totalPercentage = 0,
    validationMessage: any = {
      error: false,
      message: null
    };
  data?.forEach((item: any) => {
    totalPercentage += parseInt(item.percentage);
    if (item?.payment_term?.days && (!item?.days || parseInt(item?.days) <= 0)) {
      validationMessage = {
        error: true,
        message: `Enter valid number of days for ${item?.payment_term?.payment_term}`
      };
    }
  });
  if (totalPercentage !== 100) {
    validationMessage = {
      error: true,
      message: 'Total percentage in payment terms should be 100'
    };
  }
  return validationMessage;
};

export const calculateNetAmount = (data: any) => {
  if (!data) return 0;
  return (
    parseFloat(data.gross_invoice_amount) -
    parseFloat(data.tds_amount) +
    parseFloat(data.tcs_amount) +
    parseFloat(data.additional_charges_or_discounts)
  );
};

export const sortByUploadedOnNewToOld = (a: any, b: any) => {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
};

export const sortByUploadedOnOldToNew = (a: any, b: any) => {
  return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
};

export const sortByDocumentTypeAZ = (a: any, b: any) => {
  return a.document_type_display_value.localeCompare(b.document_type_display_value);
};

export const sortByDocumentTypeZA = (a: any, b: any) => {
  return b.document_type_display_value.localeCompare(a.document_type_display_value);
};