import Images from '@assets/images';
import {
  Badge,
  Button,
  Divider,
  Drawer,
  IconNode,
  Skeleton,
  ToolTip,
  Typography
} from '@components/base';
import { PaginationScroller, SearchBar, Seo } from '@components/common';
import { getToken, parseJWT } from '@helpers/auth-helper';
import { TASKS_MAP } from '@helpers/constants';
import notify from '@helpers/toastify-helper';
import ReviewApprovePICard from '@pages/order/order-individual/components/task-tab/PI-review-approve-card';
import ReviewApprovePOCard from '@pages/order/order-individual/components/task-tab/PO-review-approve-card';
import AddAllSuppliers from '@pages/order/order-individual/components/task-tab/add-all-suppliers-task';
import AddCourierDetailsCard from '@pages/order/order-individual/components/task-tab/add-courier-details-card';
import AddTransportDetailCard from '@pages/order/order-individual/components/task-tab/add-transport-details';
import ApproveOrderCard from '@pages/order/order-individual/components/task-tab/approve-order-card';
import AssignmentCard from '@pages/order/order-individual/components/task-tab/assignment-card';
import GenerateBOECard from '@pages/order/order-individual/components/task-tab/generate-BOE-card';
import GenerateCICard from '@pages/order/order-individual/components/task-tab/generate-CI-Card';
import GenerateCRL from '@pages/order/order-individual/components/task-tab/generate-CRL-Card';
import GeneratePICard from '@pages/order/order-individual/components/task-tab/generate-PI-card';
import GeneratePOCard from '@pages/order/order-individual/components/task-tab/generate-PO-card';
import LiftingDatesCard from '@pages/order/order-individual/components/task-tab/lifting-dates-card';
import PaymentCard from '@pages/order/order-individual/components/task-tab/payment-card';
import ShipmentDocs from '@pages/order/order-individual/components/task-tab/post-shipment-docs';
import RectifyPICard from '@pages/order/order-individual/components/task-tab/rectify-PI-card';
import RectifyPOCard from '@pages/order/order-individual/components/task-tab/rectify-PO-order';
import RectifyOrderCard from '@pages/order/order-individual/components/task-tab/rectify-order-card';
import RODTEPDrawbackCard from '@pages/order/order-individual/components/task-tab/ro-dtep-drawback-card';
import UpdateGateInCard from '@pages/order/order-individual/components/task-tab/update-gate-in-card';
import MaterialLiftingConfirmation from '@pages/order/order-individual/components/task-tab/update-material-lifting-confirmation';
import UpdateShipmentClearanceCard from '@pages/order/order-individual/components/task-tab/update-shipment-clearance-card';
import UploadOrderAcceptance from '@pages/order/order-individual/components/task-tab/upload-customer-order-acceptance';
import VesselCard from '@pages/order/order-individual/components/task-tab/vessel-dispatch-card';
import AddContainerData from '@pages/order/order-individual/components/task-tab/add-container-data';
import AddBLDetails from '@pages/order/order-individual/components/task-tab/add-bl-details';
import GenerateCommercialInvoiceCard from '@pages/order/order-individual/components/task-tab/generate-commercial-invoice';
import GenerateCOACard from '@pages/order/order-individual/components/task-tab/generate-coa-card';
import ProofOfExport from '@pages/order/order-individual/components/task-tab/proof-of-export';
import { getTaskForAnOrder } from '@services/task.service';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { getValidEntriesLength, TASK_STATUS_MAPPING } from '@helpers/utils';
import { Chip } from '@components/base';
import NoDocumentPlaceHolder from '@components/common/no-document-placeholder';
import css from './index.module.scss';
import TaskCard from '@pages/task/task-home/components/task-card';
import GenerateCOO from '@pages/order/order-individual/components/task-tab/generate-COO';
import {
  Verify,
  Rectify,
  Review
} from '@pages/order/order-individual/components/task-tab/packaging-details';
import AddBatch from '@pages/order/order-individual/components/task-tab/batch-number';
import GeneratePostPackingList from '@pages/order/order-individual/components/task-tab/generate-post-shipment-packing-list';
import GeneratePackingList from '@pages/order/order-individual/components/task-tab/generate-packing-list';
import AccessWrapper from '@authorization/access-wrapper';
import GenerateSICard from '@pages/order/order-individual/components/task-tab/generate-SI-card';
import AddEstimate from '@pages/order/order-individual/components/task-tab/add-estimate';
import UploadBlDraft from '@pages/order/order-individual/components/task-tab/upload-bl-draft';
import AddOperationCosts from '@pages/order/order-individual/components/task-tab/add-operation-costs';
import { useScrollToTop } from '@helpers/hooks';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LCDetailsCard from '@pages/order/order-individual/components/task-tab/upload-lc-details';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FilterDrawer from './components/filter';
import moment from 'moment';
import { ISelect } from '@helpers/types';

interface IFormType {
  startTaskDate: Date | null;
  endTaskDate: Date | null;
  orderId: IReadableOrderId[];
  taskType: ISelect[];
}

interface TasksHomeStates {
  tasksList: any[];
  isLoading: boolean;
  customerCount: number;
  hasNext: boolean;
  currentPageNumber: number;
  retry: boolean;
  searchText: string;
  searchLoading: boolean;
  state: number;
  showFilterDrawer: boolean;
  filters: IFormType | null;
  filterData: any;
}

interface IReadableOrderId {
  order_id: string;
  readable_order_id: string;
}

const TASK_MAPPING = [
  {
    value: 0,
    key: 'all'
  },
  {
    value: 1,
    key: 'in_progress',
    default: true
  },
  {
    value: 2,
    key: 'past_completed'
  }
];

const getDefaultTab = () => {
  const defaultTab = TASK_MAPPING.find((tab) => tab.default);
  return defaultTab ? defaultTab : TASK_MAPPING[0];
};
const defaultTab = getDefaultTab();

const TaskHome = (props: any) => {
  const { actions = {} } = props.modulePermissions;
  const debounceSearch = useRef<any>();
  const navigate = useNavigate();
  const rootContainer = useRef<HTMLElement>(null);
  const [itemLimit, setItemLimit] = useState(10);
  const topRef = useRef<HTMLElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPageNumber = parseInt(searchParams.get('page') || '1');
  const activeTabParam = searchParams.get('activeTab');
  const initialTaskState = getValueByKey(activeTabParam || defaultTab.key);

  function getValueByKey(key: string) {
    const task = TASK_MAPPING.find((item) => item.key === key);
    return task ? task.value : defaultTab.value;
  }

  useEffect(() => {
    window.addEventListener('popstate', () => {
      navigate(0);
    });
  }, [activeTabParam]);

  const [tasksState, setTasksState] = useState<TasksHomeStates>({
    tasksList: [],
    isLoading: false,
    customerCount: 0,
    hasNext: false,
    currentPageNumber: initialPageNumber,
    retry: false,
    searchText: '',
    searchLoading: false,
    state: initialTaskState,
    showFilterDrawer: false,
    filters: null,
    filterData: null
  });

  const {
    tasksList,
    customerCount,
    currentPageNumber,
    hasNext,
    isLoading,
    retry,
    searchText,
    searchLoading,
    state,
    showFilterDrawer,
    filters,
    filterData
  } = tasksState;

  const fetchAllTasks = async (page = 1, searchText?: string) => {
    try {
      const accessToken = getToken('access');
      const jwtData = parseJWT(accessToken);
      const response = await getTaskForAnOrder(
        '',
        page,
        jwtData?.user_id,
        TASK_STATUS_MAPPING.find((item: any) => item.state === state)?.isCompleted
      );
      if (response.success) {
        const { data } = response;
        setTasksState((prevState) => ({
          ...prevState,
          tasksList: data.results,
          customerCount: data.count,
          hasNext: !!data.next,
          currentPageNumber: page ?? 1,
          retry: false,
          searchLoading: false,
          filters: null,
          filterData: null
        }));
      } else if (response.error) {
        // notify({
        //   message: response.error ?? 'Something went Wrong!',
        //   severity: 'error',
        //   dismissible: true
        // });
        setTasksState((prevState) => ({ ...prevState, retry: true, searchLoading: false }));
      }
    } catch (error) {
      notify({ message: 'Something went Wrong!', severity: 'error', dismissible: true });
    }
  };

  const getFilteredTasks = async (
    state = 1,
    page = 1,
    taskCreatedStartDate?: string,
    taskCreatedEndDate?: string,
    taskTypeList?: string[],
    orderIdList?: string[],
    searchText?: string
  ) => {
    setTasksState((prevState) => ({ ...prevState, state: state }));
    try {
      const accessToken = getToken('access');
      const jwtData = parseJWT(accessToken);
      const response = await getTaskForAnOrder(
        '',
        page,
        jwtData?.user_id,
        TASK_STATUS_MAPPING.find((item: any) => item.state === state)?.isCompleted,
        taskCreatedStartDate,
        taskCreatedEndDate,
        taskTypeList,
        orderIdList
      );
      if (response.success) {
        const { data } = response;
        setTasksState((prevState) => ({
          ...prevState,
          tasksList: [...data.results],
          customerCount: data.count,
          hasNext: !!data.next,
          currentPageNumber: page ?? 1,
          retry: false,
          searchLoading: false
        }));
        const queryKey = TASK_MAPPING.find((item) => item.value === state);
        setSearchParams({
          ...Object.fromEntries(searchParams),
          page: page.toString(),
          activeTab: queryKey?.key ?? defaultTab.key
        });
      } else if (response.error) {
        // notify({ message: response.error, severity: 'error', dismissible: true });
        setTasksState((prevState) => ({ ...prevState, retry: true, searchLoading: false }));
      }
    } catch (error) {
      notify({ message: 'Something went Wrong!', severity: 'error', dismissible: true });
    }
  };

  // const fetchSearchResult = useCallback(async (searchText: string) => {
  //   setTasksState((prevState) => ({
  //     ...prevState,
  //     supplierList: [],
  //     supplierCount: 0,
  //     hasNext: false,
  //     currentPageNumber: 1,
  //     retry: false,
  //     searchLoading: true
  //   }));
  //   await getTaskForAnOrder('', 1);
  // }, []);

  // const handleCustomerSearch = async (event: ChangeEvent<HTMLInputElement>) => {
  //   const value = event.currentTarget.value;
  //   setTasksState((prevState) => ({ ...prevState, searchText: value, searchLoading: true }));
  //   debounceSearch.current?.cancel();
  //   debounceSearch.current = debounce(fetchSearchResult, 800);
  //   debounceSearch.current(value);
  // };

  const changePageNumber = (event: any, value: number) => {
    if (value === 0 || value > Math.ceil(customerCount / itemLimit)) return;
    setTasksState((prevState) => ({
      ...prevState,
      currentPageNumber: value
    }));
  };

  const handlePageChange = async (newPage: number) => {
    const queryKey = TASK_MAPPING.find((item) => item.value === initialTaskState);
    setTasksState((prev) => ({ ...prev, isLoading: true }));
    filterData
      ? await getFilteredTasks(state, newPage, ...filterData, searchText)
      : await getFilteredTasks(state, newPage, searchText);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: newPage.toString(),
      activeTab: queryKey?.key ?? defaultTab.key
    });
    setTasksState((prevState) => ({ ...prevState, isLoading: false }));
  };

  const handleFilterClick = () => {
    setTasksState((prevState) => ({
      ...prevState,
      showFilterDrawer: !prevState.showFilterDrawer
    }));
  };

  useEffect(() => {
    handlePageChange(currentPageNumber);
  }, [currentPageNumber]);

  const handleFilterApply = async (data: IFormType) => {
    setTasksState((prevState) => ({
      ...prevState,
      isLoading: true,
      showFilterDrawer: !showFilterDrawer
    }));
    const date = new Date();
    const orderIds = data?.orderId?.map((item: IReadableOrderId) => {
      return item.order_id;
    });
    const taskTypes = data?.taskType?.map((item: ISelect) => item.value);
    const taskCreationStartDate = data?.startTaskDate
      ? `${moment(data.startTaskDate).format('YYYY-MM-DD')}`
      : '';
    const taskCreationEndDate = data?.startTaskDate
      ? data?.endTaskDate
        ? `${moment(data.endTaskDate).format('YYYY-MM-DD')}`
        : `${moment(date).format('YYYY-MM-DD')}`
      : '';

    await getFilteredTasks(
      state,
      1,
      taskCreationStartDate,
      taskCreationEndDate,
      taskTypes,
      orderIds
    );
    setTasksState((prevState) => ({
      ...prevState,
      filterData: [taskCreationStartDate, taskCreationEndDate, taskTypes, orderIds],
      filters: data,
      isLoading: false
    }));
  };

  const handleChipClick = async (newState: number) => {
    setTasksState((prevState) => ({ ...prevState, isLoading: true }));
    if (state === newState) return;
    filterData
      ? await getFilteredTasks(newState, 1, ...filterData, searchText)
      : await getFilteredTasks(newState, 1);
    setTasksState((prevState) => ({ ...prevState, isLoading: false }));
  };

  const handleClearFilter = async () => {
    setTasksState((prevState) => ({
      ...prevState,
      filters: null,
      filterData: null,
      isLoading: true
    }));
    await getFilteredTasks(state);
    setTasksState((prevState) => ({
      ...prevState,
      isLoading: false
    }));
  };

  useScrollToTop({ topRef, dependencyArray: [currentPageNumber, state] });

  return (
    <AccessWrapper show={actions?.read} showUnauthorised>
      <main ref={rootContainer} className={css.mainWrapper}>
        <Seo title="Task" />
        <div className={css.header}>
          <div className={css.customerTextWrapper}>
            <Typography variant="h2">Tasks</Typography>
            <Badge>{customerCount}</Badge>
          </div>
        </div>
        <Divider className={css.divider} />
        <div className={css.filterWrapper}>
          <div className={css.filterButtonWrapper}>
            <Button
              className={css.filterButton}
              variant="outlined"
              startIcon={<IconNode src={Images.filter} alt="filter icon" />}
              onClick={handleFilterClick}>
              Filter
            </Button>
            {getValidEntriesLength(filterData) > 0 && (
              <Button
                variant="text"
                onClick={handleClearFilter}
                startIcon={<IconNode src={Images.crossRed} alt="clear icon" />}>
                Clear Filters
              </Button>
            )}
          </div>

          <div className={css.taskChipWrapper}>
            <Chip
              label="All"
              variant={state == 0 ? `filled` : `outlined`}
              onClick={() => handleChipClick(0)}
            />
            <Chip
              label="In Progress"
              variant={state == 1 ? `filled` : `outlined`}
              onClick={() => handleChipClick(1)}
            />
            <Chip
              label="Past/Completed"
              variant={state == 2 ? `filled` : `outlined`}
              onClick={() => handleChipClick(2)}
            />
          </div>

          <div className={css.paginationFilter}>
            <ToolTip title="Previous Page">
              <ArrowBackIosIcon
                sx={{
                  height: '40px',
                  cursor: currentPageNumber > 1 ? 'pointer' : 'default',
                  color: currentPageNumber > 1 ? '#000000' : '#e2e2e2'
                }}
                onClick={() => changePageNumber(null, currentPageNumber - 1)}
              />
            </ToolTip>
            <Typography className={css.pageOfTotalPage}>
              {currentPageNumber} of {Math.ceil(customerCount / itemLimit)}
            </Typography>
            <ToolTip title="Next Page">
              <ArrowForwardIosIcon
                sx={{
                  height: '40px',
                  cursor:
                    currentPageNumber < Math.ceil(customerCount / itemLimit)
                      ? 'pointer'
                      : 'default',
                  color:
                    currentPageNumber < Math.ceil(customerCount / itemLimit) ? '#000000' : '#e2e2e2'
                }}
                onClick={() => changePageNumber(null, currentPageNumber + 1)}
              />
            </ToolTip>
          </div>

          {/* 
          <SearchBar
            placeholder="Search for ID, Products, Customers"
            className={css.search}
            onChange={handleCustomerSearch}
            isLoading={searchLoading}
          /> */}
        </div>
        <Drawer open={tasksState.showFilterDrawer} anchor="right" onClose={handleFilterClick}>
          <FilterDrawer value={filters} onApply={handleFilterApply} onClose={handleFilterClick} />
        </Drawer>
        <section className={css.customerSection} ref={topRef}>
          {isLoading ? (
            <TaskSkeleton />
          ) : tasksList.length > 0 ? (
            tasksList?.map((task: any, index: number) => (
              <Fragment key={index}>
                {(() => {
                  switch (task?.task_type) {
                    case TASKS_MAP.assignPersonnel:
                      return (
                        <AssignmentCard
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.generatePI:
                      return (
                        <GeneratePICard
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        />
                      );

                    case TASKS_MAP.addRodtepDrawback:
                      return (
                        <RODTEPDrawbackCard
                          taskId={task.task_id as string}
                          onComplete={fetchAllTasks}
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        />
                      );

                    case TASKS_MAP.upadteGateIn:
                      return (
                        <UpdateGateInCard
                          taskId={task.task_id as string}
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        />
                      );

                    case TASKS_MAP.updateShipmentClearance:
                      return (
                        <UpdateShipmentClearanceCard
                          onComplete={fetchAllTasks}
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        />
                      );

                    case TASKS_MAP.addLiftingDates:
                      return (
                        <LiftingDatesCard
                          taskData={tasksList.filter((item) => item.task_id == task.task_id)}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.reviewPI:
                      return (
                        <ReviewApprovePICard
                          taskData={tasksList.filter((item) => item.task_id == task.task_id)}
                        />
                      );

                    case TASKS_MAP.rectifyPI:
                      return (
                        <RectifyPICard
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        />
                      );

                    case TASKS_MAP.postShipmentDoc:
                      return (
                        <ShipmentDocs
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                          shipmentType="post-shipment"
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.preShipmentDoc:
                      return (
                        <ShipmentDocs
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                          shipmentType="pre-shipment"
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.exportClearance:
                      return (
                        <ShipmentDocs
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                          shipmentType="export-clearance"
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.paymentProof:
                      return (
                        <PaymentCard
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.addTransportDetails:
                      return (
                        <AddTransportDetailCard
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        />
                      );

                    case TASKS_MAP.materialLiftingConfirmation:
                      return (
                        <MaterialLiftingConfirmation
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.generateCI:
                      return (
                        <GenerateCICard
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        />
                      );

                    case TASKS_MAP.addCourierDetails:
                      return (
                        <AddCourierDetailsCard
                          onComplete={fetchAllTasks}
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        />
                      );

                    case TASKS_MAP.vesselDispatch:
                      return (
                        <VesselCard
                          taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.uploadCustomerOrderAcceptance:
                      return (
                        <UploadOrderAcceptance
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.addAllSuppliers:
                      return (
                        <AddAllSuppliers
                          taskId={task.task_id as string}
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.rectifyOrder:
                      return (
                        <RectifyOrderCard
                          taskData={tasksList.filter((item) => item.task_id == task.task_id)}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.reviewOrder:
                      return (
                        <ApproveOrderCard
                          taskData={tasksList.filter((item) => item.task_id == task.task_id)}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.generateCRL:
                      return (
                        <GenerateCRL
                          taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                        />
                      );

                    case TASKS_MAP.generateBOE:
                      return (
                        <GenerateBOECard
                          taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                        />
                      );

                    case TASKS_MAP.generatePO:
                      return (
                        <GeneratePOCard
                          generatedBy="sas"
                          taskStatus="critical"
                          priority="high"
                          taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                        />
                      );

                    case TASKS_MAP.rectifyPO:
                      return (
                        <RectifyPOCard
                          taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                        />
                      );

                    case TASKS_MAP.reviewPO:
                      return (
                        <ReviewApprovePOCard
                          taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                        />
                      );

                    case TASKS_MAP.uploadECGCandDNB:
                      return (
                        <TaskCard
                          taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.addContainerData:
                      return (
                        <AddContainerData
                          taskData={tasksList.find((item) => item.task_id == task.task_id)}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.addBLDetails:
                      return (
                        <AddBLDetails
                          taskData={tasksList.find((item) => item.task_id == task.task_id) ?? {}}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.generateCommercialInvoice:
                      return (
                        <GenerateCommercialInvoiceCard
                          taskData={tasksList.find((item) => item.task_id == task.task_id) ?? {}}
                          onComplete={fetchAllTasks}
                        />
                      );
                    case TASKS_MAP.generateCOA:
                      return (
                        <GenerateCOACard
                          taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                        />
                      );
                    case TASKS_MAP.proofOfExport:
                      return (
                        <ProofOfExport
                          taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.generateCOO:
                      return (
                        <GenerateCOO
                          taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.verifyPackingDetails:
                      return (
                        <Verify
                          taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.approvePackingDetails:
                      return (
                        <Review
                          taskData={tasksList.find((item) => item.task_id == task.task_id) ?? {}}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.rectifyPackingDetails:
                      return (
                        <Rectify
                          taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.addBatchNumber:
                      return (
                        <AddBatch
                          taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.generatePreShipmentPackingList:
                      return (
                        <GeneratePackingList
                          taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                        />
                      );

                    case TASKS_MAP.generatePostShipmentPackingList:
                      return (
                        <GeneratePostPackingList
                          taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                        />
                      );

                    case TASKS_MAP.generateShippingInstructions:
                      return (
                        <GenerateSICard
                          taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                        />
                      );
                    case TASKS_MAP.addEstimate:
                      return (
                        <AddEstimate
                          taskData={tasksList.find((item) => item.task_id == task.task_id) ?? {}}
                          onComplete={fetchAllTasks}
                        />
                      );

                    case TASKS_MAP.uploadBLDraft:
                      return (
                        <UploadBlDraft
                          taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                          onComplete={fetchAllTasks}
                        />
                      );
                    case TASKS_MAP.addOperationCosts:
                      return (
                        <AddOperationCosts
                          taskData={
                            tasksList.find((item: any) => item.task_id === task.task_id) ?? {}
                          }
                          onComplete={fetchAllTasks}
                        />
                      );
                    case TASKS_MAP.uploadLCDetails:
                      return (
                        <LCDetailsCard
                          taskData={
                            tasksList.find((item: any) => item.task_id === task.task_id) ?? {}
                          }
                          onComplete={fetchAllTasks}
                        />
                      );
                    default:
                      return <></>;
                  }
                })()}
              </Fragment>
            ))
          ) : (
            !isLoading && (
              <NoDocumentPlaceHolder
                title="No task to show"
                supportingText="When a task is assigned to you, it will appear here"
              />
            )
          )}
        </section>
        {customerCount > 0 && (
          <PaginationScroller
            variant="text"
            defaultPage={1}
            count={customerCount}
            pageLimit={itemLimit}
            page={currentPageNumber}
            onChange={changePageNumber}
          />
        )}
      </main>
    </AccessWrapper>
  );
};

export default TaskHome;

const TaskSkeleton = () => {
  return (
    <>
      {Array.from({ length: 10 }).map((_, index) => (
        <TaskCardSkeleton key={index} />
      ))}
    </>
  );
};

const TaskCardSkeleton = () => {
  return (
    <div className={css.taskSkeletonWrapper}>
      <div className={css.taskSkeletonHeader}>
        <div className={css.taskSkeletonHeaderLeft}>
          <Skeleton variant="circular" width={30} height={30} />
          <Skeleton variant="text" width={'70%'} height={10} />
        </div>
        <Skeleton variant="text" width={'30%'} height={30} />
      </div>
      <div className={css.taskSkeletonFooter}>
        <div className={css.taskSkeletonFooterItem}>
          <Skeleton variant="text" width={'100%'} height={20} />
          <Skeleton variant="text" width={'80%'} height={20} />
        </div>
        <div className={css.taskSkeletonFooterItem}>
          <Skeleton variant="text" width={'100%'} height={20} />
          <Skeleton variant="text" width={'80%'} height={20} />
        </div>
        <div className={css.taskSkeletonFooterItem}>
          <Skeleton variant="text" width={'100%'} height={20} />
          <Skeleton variant="text" width={'80%'} height={20} />
        </div>
      </div>
    </div>
  );
};
