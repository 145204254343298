import { ISupplier } from '@helpers/types/supplier';
import { useEffect, useState } from 'react';
import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form';
import css from './index.module.scss';
import Typography from '@components/base/typography';
import {
  AutoComplete,
  DocumentRow,
  InputDatePicker,
  SelectLabel,
  TextField
} from '@components/common';
import { Button, Chip, IconNode, Modal, ToolTip } from '@components/base';
import Images from '@assets/images';
import {
  invoiceFilterOptions,
  invoicePayment,
  requestPayment,
  supplierFilterOptions
} from '@services/finance.service';
import notify from '@helpers/toastify-helper';
import moment from 'moment';
import { requestPaymentSchema } from '@helpers/yup/payment-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PAYMENT_STATUS } from '@helpers/constants';
import { ISelect } from '@helpers/types';

interface ISupplierIdName {
  supplier_id: string;
  supplier_name: string;
}

interface IInvoiceNumber {
  invoice_number: string;
  invoice_info_id: string;
}
interface IInvoice {
  invoice_info_id: string;
  invoice_number: string;
  invoice_date: Date | null;
  total_amount_eligible_for_payment: string;
  total_amount_eligible_to_request: string;
  currency: string;
  amount: string;
  gst_amount: string;
  document: string;
  tds_amount: string;
  tcs_amount: string;
  additional_charges_or_discounts: string;
  remarks: string;
  payment_terms: string;
  gross_invoice_amount: string;
  total_amount_requested: string;
  total_amount_initiated: string;
  total_amount_paid: string;
  total_child_invoices_gross_amount: string;
}

interface IRequestPaymentState {
  isLoading: boolean;
  supplier: ISupplierIdName | null;
  invoice: IInvoice | null;
  supplierOptions: ISupplier[];
  invoiceOptions: IInvoice[];
  invoiceDetails: IInvoice | null;
  showAmountDetails: boolean;
}

interface IRequestFormInvoice {
  invoice_info_id: string | null;
  invoice_number: string | null;
  invoice_date: Date | null;
  total_amount_eligible_for_payment: string | null;
  total_amount_eligible_to_request: string | null;
  currency: ISelect | null;
  amount: string | null;
  gst_amount: string | null;
  document: string | null;
  tds_amount: string | null;
  tcs_amount: string | null;
  additional_charges_or_discounts: string | null;
  remarks: string;
  payment_terms: string | null;
  net_amount: number | null;
  gross_amount: string | null;
}

interface IRequestPaymentForm {
  supplier: ISupplierIdName | null;
  invoice_number: IInvoiceNumber | null;
  invoice: IRequestFormInvoice | null;
  amount_request: string | null;
  due_date: Date | null;
  requester_remarks: string | null;
  amount_request_decimal: number | null;
}

interface IRequestPayment {
  open: boolean;
  onClose: () => void;
  handleFetchPaymentData: (status?: string) => void;
  handlePaymentChipClick: (value: number) => void;
}

const RequestPayment = (props: IRequestPayment) => {
  const { open, onClose, handleFetchPaymentData, handlePaymentChipClick } = props;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeSubTabParam = searchParams.get('activeSubTab');
  const [requestPaymentState, setRequestPaymentState] = useState<IRequestPaymentState>({
    isLoading: false,
    supplier: null,
    invoice: null,
    supplierOptions: [],
    invoiceOptions: [],
    invoiceDetails: null,
    showAmountDetails: false
  });

  const [paymentEligibleToRequest, setPaymentEligibleToRequest] = useState<number>(0);

  const {
    isLoading,
    supplierOptions,
    invoiceOptions,
    invoiceDetails,
    supplier,
    showAmountDetails
  } = requestPaymentState;

  const requestPaymentForm = useForm<IRequestPaymentForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(requestPaymentSchema),
    defaultValues: {
      supplier: null,
      invoice_number: null,
      invoice: {
        invoice_info_id: null,
        invoice_number: null,
        invoice_date: null,
        total_amount_eligible_for_payment: null,
        total_amount_eligible_to_request: null,
        currency: null,
        amount: null,
        gst_amount: null,
        document: null,
        tds_amount: null,
        tcs_amount: null,
        additional_charges_or_discounts: null,
        remarks: '',
        payment_terms: null,
        net_amount: null,
        gross_amount: null
      },
      amount_request: null,
      due_date: null,
      requester_remarks: null,
      amount_request_decimal: null
    }
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    getValues,
    setError,
    watch
  } = requestPaymentForm;

  const amountRequestWatch = useWatch({
    control,
    name: 'amount_request'
  });
  const invoiceWatch = watch('invoice_number');
  const fetchSupplierDetails = async (event: any, params?: any) => {
    setRequestPaymentState((prevState: IRequestPaymentState) => ({
      ...prevState,
      isLoading: true
    }));
    const response = await supplierFilterOptions(params);
    setRequestPaymentState((prevState: IRequestPaymentState) => ({
      ...prevState,
      isLoading: false
    }));
    if (response.success) {
      setRequestPaymentState((prevState: IRequestPaymentState) => ({
        ...prevState,
        supplierOptions: response.data,
        isLoading: false
      }));
    } else if (response.error) {
      notify({
        message: response.error.message ?? 'Something went wrong, Contact Tech Team',
        severity: 'error'
      });
    }
  };

  const fetchInvoiceDetails = async (event: any, params?: any) => {
    setRequestPaymentState((prevState: IRequestPaymentState) => ({
      ...prevState,
      isLoading: true
    }));
    const response = await invoiceFilterOptions(params);
    setRequestPaymentState((prevState: IRequestPaymentState) => ({
      ...prevState,
      isLoading: false
    }));
    if (response.success) {
      setRequestPaymentState((prevState: IRequestPaymentState) => ({
        ...prevState,
        invoiceOptions: response.data.results
      }));
    } else if (response.error) {
      notify({
        message: response.error.message ?? 'Something went wrong, Contact Tech Team',
        severity: 'error'
      });
    }
  };

  const fetchInvoiceDetailsById = async (invoice_info_id: string) => {
    setRequestPaymentState((prevState: IRequestPaymentState) => ({
      ...prevState,
      isLoading: true
    }));
    const response = await invoicePayment(invoice_info_id);
    setRequestPaymentState((prevState: IRequestPaymentState) => ({
      ...prevState,
      isLoading: false
    }));
    if (response.success) {
      const { data } = response;
      setRequestPaymentState((prevState: IRequestPaymentState) => ({
        ...prevState,
        invoiceDetails: data
      }));
      setPaymentEligibleToRequest(data.total_amount_eligible_for_payment);

      requestPaymentForm.setValue('invoice.invoice_info_id', data.invoice_info_id);
      requestPaymentForm.setValue('invoice.invoice_number', data.invoice_number);
      requestPaymentForm.setValue(
        'invoice.total_amount_eligible_for_payment',
        data.total_amount_eligible_for_payment
      );
      requestPaymentForm.setValue(
        'invoice.total_amount_eligible_to_request',
        data.total_amount_eligible_to_request
      );
      setPaymentEligibleToRequest(data.total_amount_eligible_to_request);
      requestPaymentForm.setValue('invoice.invoice_date', new Date(data.invoice_date));
      requestPaymentForm.setValue('invoice.currency', {
        label: data.currency,
        value: data.currency
      });
      requestPaymentForm.setValue('invoice.amount', data.amount);
      requestPaymentForm.setValue('invoice.gst_amount', data.gst_amount);
      requestPaymentForm.setValue('invoice.gross_amount', data.gross_invoice_amount);
      requestPaymentForm.setValue('invoice.tds_amount', data.tds_amount);
      requestPaymentForm.setValue('invoice.tcs_amount', data.tcs_amount);
      requestPaymentForm.setValue(
        'invoice.additional_charges_or_discounts',
        data.additional_charges_or_discounts
      );

      let netInvoiceAmount = 0;
      netInvoiceAmount += parseFloat(data.gross_invoice_amount ?? '0');
      if (parseFloat(data.tds_amount)) {
        netInvoiceAmount -= parseFloat(data.tds_amount);
      } else if (parseFloat(data.tcs_amount)) {
        netInvoiceAmount += parseFloat(data.tcs_amount);
      }
      if (parseFloat(data.additional_charges_or_discounts)) {
        netInvoiceAmount += parseFloat(data.additional_charges_or_discounts);
      }
      requestPaymentForm.setValue('invoice.net_amount', netInvoiceAmount);
      requestPaymentForm.setValue('invoice.document', data.document);

      requestPaymentForm.setValue('invoice.remarks', data.remarks);
      requestPaymentForm.setValue('invoice.payment_terms', data.payment_terms_display);
    } else if (response.error) {
      notify({
        message: response.error.message ?? 'Something went wrong, Contact Tech Team',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    fetchSupplierDetails(null);
  }, []);

  useEffect(() => {
    fetchInvoiceDetails(null, { supplier_id: supplier?.supplier_id });
  }, [supplier]);

  const onFormSubmit = async (data: IRequestPaymentForm) => {
    if (paymentEligibleToRequest < 0) {
      setError('amount_request', {
        type: 'custom',
        message: 'Requested amount cannot be more than amount eligible to request'
      });
      return;
    }
    const requestBody = {
      currency: data?.invoice?.currency?.value,
      amount_to_pay: data?.amount_request_decimal,
      due_date: moment(data.due_date).format('YYYY-MM-DD'),
      requester_remarks: data.requester_remarks
    };
    let response;
    if (data?.invoice?.invoice_info_id) {
      response = await requestPayment(data.invoice.invoice_info_id, requestBody);
    } else {
      notify({
        message: 'Invoice ID is missing',
        severity: 'error'
      });
      return;
    }
    if (response.success) {
      notify({
        message: response.data.message ?? 'Payment Requested Successfully',
        severity: 'success'
      });
      onClose();

      if (activeSubTabParam === PAYMENT_STATUS.REQUESTED) {
        handleFetchPaymentData(PAYMENT_STATUS.REQUESTED);
      } else {
        handlePaymentChipClick(0);
      }
    } else if (response.error) {
      notify({
        message: response.error.message ?? 'Something went wrong, Contact Tech Team',
        severity: 'error'
      });
    }
  };

  const onSupplierSelection = (event: any, supplier: ISupplier) => {
    setRequestPaymentState((prevState: IRequestPaymentState) => ({
      ...prevState,
      supplier: supplier
    }));
    requestPaymentForm.resetField('invoice_number');
    requestPaymentForm.setValue('supplier', supplier);
  };

  const onInvoiceSelection = (event: any, invoice: IInvoice) => {
    setRequestPaymentState((prevState: IRequestPaymentState) => ({
      ...prevState,
      invoice: invoice
    }));
    fetchInvoiceDetailsById(invoice?.invoice_info_id);
    if (invoice) {
      requestPaymentForm.setValue('invoice_number', {
        invoice_number: invoice.invoice_number ?? null,
        invoice_info_id: invoice.invoice_info_id
      });
    } else {
      requestPaymentForm.resetField('invoice_number');
    }
    requestPaymentForm.setValue('amount_request', null);
    requestPaymentForm.setValue('amount_request_decimal', null);
  };

  const handleCancel = () => {
    onClose();
    requestPaymentForm.reset();
  };

  useEffect(() => {
    if (amountRequestWatch !== null && !isNaN(parseFloat(amountRequestWatch))) {
      const paymentEligibleToRequest =
        parseFloat(invoiceDetails?.total_amount_eligible_to_request ?? '0') -
        (parseFloat(amountRequestWatch) / 100) *
          parseFloat(invoiceDetails?.total_amount_eligible_for_payment ?? '0');
      setPaymentEligibleToRequest(paymentEligibleToRequest);
      requestPaymentForm.setValue(
        'amount_request_decimal',
        parseFloat(invoiceDetails?.total_amount_eligible_to_request ?? '0') -
          paymentEligibleToRequest
      );
    } else {
      setPaymentEligibleToRequest(
        parseFloat(invoiceDetails?.total_amount_eligible_to_request ?? '0')
      );
      requestPaymentForm.setValue('amount_request_decimal', null);
    }
  }, [amountRequestWatch, invoiceDetails]);

  const requestAllAmount = () => {
    const percentAmountRequestable =
      (parseFloat(invoiceDetails?.total_amount_eligible_to_request ?? '0') /
        parseFloat(invoiceDetails?.total_amount_eligible_for_payment ?? '0')) *
      100;
    requestPaymentForm.setValue('amount_request', percentAmountRequestable.toString());
  };

  return (
    <Modal open={open} onClose={handleCancel}>
      <main className={css.mainWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Request Payment</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
          <section className={css.modalContent}>
            <div className={css.autoCompleteWrapper}>
              <Controller
                name="supplier"
                control={requestPaymentForm.control}
                render={({ field, fieldState }) => (
                  <AutoComplete
                    {...field}
                    label="Supplier"
                    placeholder="Start typing"
                    options={supplierOptions}
                    onInputChange={(event: any, value: string) =>
                      fetchSupplierDetails(event, { search_text: value })
                    }
                    onInputSelection={onSupplierSelection}
                    keyOption="supplier_name"
                    key="supplier_id"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    width="100%"
                  />
                )}
              />
              <Controller
                name="invoice_number"
                control={requestPaymentForm.control}
                render={({ field, fieldState }) => (
                  <AutoComplete
                    {...field}
                    required
                    label="Invoice"
                    placeholder="Start typing"
                    options={invoiceOptions}
                    onInputChange={(event: any, value: string) =>
                      fetchInvoiceDetails(event, {
                        search_text: value,
                        supplier_id: supplier?.supplier_id
                      })
                    }
                    onInputSelection={onInvoiceSelection}
                    keyOption="invoice_number"
                    key="invoice_info_id"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    width="100%"
                  />
                )}
              />
            </div>
            <div className={css.invoiceDetailsWrapper}>
              <div className={css.invoiceDetailsHeader}>
                <Controller
                  name={`invoice.invoice_date`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputDatePicker
                      {...field}
                      placeholder="Select date"
                      label="Invoice Date"
                      disabled
                      onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      rootClassName={css.fieldSpacing}
                    />
                  )}
                />
                <Controller
                  name={`invoice.currency`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <SelectLabel
                      {...field}
                      label="Currency"
                      isDisabled
                      placeholder="Select"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Controller
                  name={`invoice.gross_amount`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      disabled
                      enterKeyHint="next"
                      autoComplete="off"
                      label="Gross Amount"
                      placeholder="Start typing"
                      value={field.value ?? ''}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      endIcon={
                        fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                      }
                    />
                  )}
                />
              </div>
              <div className={css.rowWrapper}>
                <Controller
                  name={`invoice.payment_terms`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      disabled
                      value={field.value ?? ''}
                      enterKeyHint="next"
                      autoComplete="off"
                      label="Payment Terms"
                      placeholder="Start typing"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      endIcon={
                        fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                      }
                    />
                  )}
                />
                <Controller
                  name={`invoice.remarks`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      disabled
                      enterKeyHint="next"
                      autoComplete="off"
                      label="Remarks"
                      placeholder="Start typing"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      endIcon={
                        fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                      }
                    />
                  )}
                />
              </div>
              {invoiceWatch ? (
                <Button
                  variant="text"
                  onClick={() =>
                    setRequestPaymentState((prevState: IRequestPaymentState) => {
                      return {
                        ...prevState,
                        showAmountDetails: !prevState.showAmountDetails
                      };
                    })
                  }>
                  See how these amounts are calculated?
                </Button>
              ) : (
                <></>
              )}

              {showAmountDetails && (
                <div className={css.amountBreakdown}>
                  <div>
                    <b>
                      Gross Amount ({invoiceDetails?.currency}{' '}
                      {invoiceDetails?.gross_invoice_amount}){' '}
                    </b>
                    = Invoice Amount ({invoiceDetails?.currency} {invoiceDetails?.amount ?? 0}){' '}
                    {invoiceDetails?.gst_amount?.length
                      ? `+ GST Amount (${invoiceDetails?.currency} ${invoiceDetails?.gst_amount})`
                      : ``}
                  </div>
                  <div>
                    <b>
                      {' '}
                      Net Amount ({invoiceDetails?.currency} {getValues('invoice.net_amount')}) ={' '}
                    </b>
                    Gross Amount ({invoiceDetails?.currency} {invoiceDetails?.gross_invoice_amount})
                    {invoiceDetails?.tds_amount?.length &&
                    parseFloat(invoiceDetails?.tds_amount ?? '0')
                      ? ` - TDS Amount (${invoiceDetails?.currency} ${invoiceDetails?.tds_amount})`
                      : ''}
                    {invoiceDetails?.tcs_amount?.length &&
                    parseFloat(invoiceDetails?.tcs_amount ?? '0')
                      ? ` + TCS Amount (${invoiceDetails?.currency} ${invoiceDetails?.tcs_amount})`
                      : ''}
                    {invoiceDetails?.additional_charges_or_discounts?.length &&
                    parseFloat(invoiceDetails?.additional_charges_or_discounts ?? '0')
                      ? `${
                          parseFloat(invoiceDetails?.tcs_amount ?? '0') > 0
                            ? ` +/- Add. Charges or discounts  (${invoiceDetails?.currency} ${invoiceDetails?.additional_charges_or_discounts})`
                            : ''
                        }`
                      : ''}
                  </div>
                  <div>
                    <b>Total Amount Eligible to Request ({invoiceDetails?.currency} </b>
                    {paymentEligibleToRequest}) = Net Amount ({invoiceDetails?.currency}{' '}
                    {getValues('invoice.net_amount')})
                    {invoiceDetails?.total_amount_requested?.length &&
                    parseFloat(invoiceDetails?.total_amount_requested ?? '0')
                      ? ` - Total Amount Requested (${invoiceDetails?.currency} ${invoiceDetails?.total_amount_requested})`
                      : ''}
                    {invoiceDetails?.total_amount_initiated?.length &&
                    parseFloat(invoiceDetails?.total_amount_initiated ?? '0')
                      ? ` - Total Amount Initiated (${invoiceDetails?.currency} ${invoiceDetails?.total_amount_initiated})`
                      : ''}
                    {invoiceDetails?.total_amount_paid?.length &&
                    parseFloat(invoiceDetails?.total_amount_paid ?? '0')
                      ? ` - Total Amount Paid (${invoiceDetails?.currency} ${invoiceDetails?.total_amount_paid})`
                      : ''}
                    {invoiceDetails?.total_child_invoices_gross_amount?.length &&
                    parseFloat(invoiceDetails?.total_child_invoices_gross_amount ?? '0')
                      ? ` - Sum Total of other tax invoices (${invoiceDetails?.currency} ${invoiceDetails?.total_child_invoices_gross_amount})`
                      : ''}
                  </div>
                  {/* <div className={css.rowWrapper}>
                    <Controller
                      name={`invoice.amount`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          disabled
                          enterKeyHint="next"
                          autoComplete="off"
                          label="Invoice Amount"
                          placeholder="Start typing"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="error icon" />
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name={`invoice.gst_amount`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          disabled
                          enterKeyHint="next"
                          autoComplete="off"
                          label="GST Amount"
                          placeholder="Start typing"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="error icon" />
                            )
                          }
                        />
                      )}
                    />
                  </div>
                  <div className={css.rowWrapper}>
                    <Controller
                      name={`invoice.additional_charges_or_discounts`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          disabled
                          enterKeyHint="next"
                          autoComplete="off"
                          label="Additional Charges or Discounts"
                          placeholder="Start typing"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="error icon" />
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name={`invoice.tds_amount`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          disabled
                          enterKeyHint="next"
                          autoComplete="off"
                          label="TDS Amount"
                          placeholder="Start typing"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="error icon" />
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name={`invoice.tcs_amount`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          disabled
                          enterKeyHint="next"
                          autoComplete="off"
                          label="TCS Amount"
                          placeholder="Start typing"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="error icon" />
                            )
                          }
                        />
                      )}
                    />
                  </div>
                  <div className={css.rowWrapper}>
                    <Controller
                      name={`invoice.net_amount`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          disabled
                          enterKeyHint="next"
                          autoComplete="off"
                          label="Net Amount"
                          placeholder="Start typing"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="error icon" />
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name={`invoice.amount_el`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          disabled
                          enterKeyHint="next"
                          autoComplete="off"
                          label="Net Amount"
                          placeholder="Start typing"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="error icon" />
                            )
                          }
                        />
                      )}
                    />
                  </div> */}
                </div>
              )}
            </div>
            <div>
              <Typography variant="label">Amount Eligible to Request</Typography>
              {!paymentEligibleToRequest && <Chip variant="filled-blue" label={'0'} />}
              {paymentEligibleToRequest >= 0 ? (
                <div>
                  <span style={{ display: 'flex' }}>
                    <Chip variant="filled-green" label={paymentEligibleToRequest} />
                    {invoiceWatch && paymentEligibleToRequest != 0 ? (
                      <Button style={{ marginLeft: 10 }} variant="text" onClick={requestAllAmount}>
                        Request All
                      </Button>
                    ) : (
                      <></>
                    )}
                  </span>

                  {parseFloat(amountRequestWatch ?? '0') > 0 && (
                    <ToolTip
                      title={`Net Amount - Amount already requested - ${amountRequestWatch}% of Gross Amount `}
                      placement="right">
                      <span className={css.hoverText}>How is this calculated?</span>
                    </ToolTip>
                  )}
                </div>
              ) : (
                <>
                  <Chip variant="filled-red" label={paymentEligibleToRequest} />
                </>
              )}
            </div>
            <div className={css.rowWrapper}>
              <Controller
                name="amount_request"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    value={field.value === null ? '' : field.value}
                    label="Request Amount %"
                    placeholder="Enter %"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                    }
                  />
                )}
              />
              <Controller
                name="due_date"
                control={requestPaymentForm.control}
                render={({ field, fieldState }) => (
                  <InputDatePicker
                    {...field}
                    required
                    label="Due Date"
                    onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldSpacing}
                  />
                )}
              />
            </div>
            <Controller
              name="requester_remarks"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={field.value === null ? '' : field.value}
                  label="Requester Remarks"
                  placeholder="Start typing"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
          </section>
          <section className={css.modalFooter}>
            <Button variant="outlined-secondary" onClick={handleCancel}>
              Close
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Saving' : 'Save'}
            </Button>
          </section>
        </form>
      </main>
    </Modal>
  );
};

export default RequestPayment;
