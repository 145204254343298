import { useCallback, useState, useEffect } from 'react';
import css from './index.module.scss';
import { Loader, Typography } from '@components/base';
import { Seo, SideBar, SupplierDetailForm, BankDetailForm } from '@components/common';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import {
  createSupplier,
  getSupplierFilterOptions,
  updateSupplierInfo
} from '@services/supplier.service';
import notify from '@helpers/toastify-helper';
import { addBankDetailSchema, addSupplierSchema } from '@helpers/yup';
import {
  IBankDetailForm,
  ISupplierDetailForm,
  ISupplier,
  ISupplierFilterOptions
} from '@helpers/types/supplier';
import { CLIENT_ROUTES } from '@router/routes';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import {
  getSupplierDetailFormValuesFromResponse,
  getRequestBodyForSupplierDetail,
  getRequestBodyForSupplierBankDetail
} from '@helpers/utils';
import { fetchAllUsers } from '@services/user.service';
import AccessWrapper from '@authorization/access-wrapper';

interface SupplierAddState {
  activeStep: number;
  showVendorId: boolean;
  previousFormState: ISupplierDetailForm | null;
  //Used to redirect to supplier detail page if user clicks cancel on the bank detail form
  newlyCreatedSupplierId: string | null;
  userList: Array<object>;
  filterOptions: ISupplierFilterOptions;
  supplierInfo: ISupplier | null;
}

const SupplierAdd = (props: any) => {
  const { actions } = props.modulePermissions;
  const [supplierState, setSupplierState] = useState<SupplierAddState>({
    activeStep: 0,
    showVendorId: false,
    previousFormState: null,
    newlyCreatedSupplierId: null,
    userList: [],
    filterOptions: {
      business_type_filter_options: [],
      supplier_origin_filter_options: [],
      supplier_type_filter_options: []
    },
    supplierInfo: null
  });

  useBeforeUnloadAndNavigate();

  const navigate = useNavigate();

  const {
    activeStep,
    showVendorId,
    previousFormState,
    newlyCreatedSupplierId,
    userList,
    filterOptions
  } = supplierState;

  useEffect(() => {
    fetchUsersForPocList();
    fetchFilterOptions();
  }, []);

  const firstFormMethods = useForm<ISupplierDetailForm>({
    defaultValues: {
      supplier_id: '',
      supplier_name: '',
      vendor_id: '',
      supplier_email: '',
      supplier_website: '',
      gst_number: '',
      supplier_phone_number: '',
      supplier_landline: '',
      supplier_type: {
        label: 'Indian',
        value: 'INDIAN'
      },
      business_type: null,
      address_id: '',
      address_type: 'OFC',
      address_line1: '',
      address_line2: '',
      zip_code: '',
      city: null,
      state: null,
      country: null,
      same_as_office: false,
      addresses: [
        {
          address_id: '',
          address_type: 'FAC',
          address_line1: '',
          address_line2: '',
          zip_code: '',
          city: null,
          state: null,
          country: null,
          address_suffix: null
        }
      ],
      pocs: [
        {
          user_id: '',
          given_name: '',
          family_name: '',
          email: '',
          phone_number: ''
        }
      ],
      type: null
    },
    resolver: yupResolver(addSupplierSchema)
  });

  const secondFormMethods = useForm<IBankDetailForm>({
    defaultValues: {
      bankdetails: [
        {
          bank_id: '',
          bank_name: '',
          branch_name: '',
          bank_account_holder_name: '',
          account_number: '',
          ifsc_code: '',
          swift_code: '',
          address_line1: '',
          address_line2: '',
          zip_code: '',
          city: null,
          state: null,
          country: null,
          swift_code_required: true
        }
      ]
    },
    resolver: yupResolver(addBankDetailSchema)
  });

  const {
    setError: firstSetError,
    reset: firstReset,
    formState: { isSubmitting: firstIsSubmitting }
  } = firstFormMethods;

  const {
    formState: { isSubmitting: secondIsSubmitting }
  } = secondFormMethods;

  const handleSidebarNavigation = useCallback(
    (value: number) => {
      const { previousFormState, activeStep } = supplierState;
      if (value === 0 && activeStep !== 0 && previousFormState) {
        firstReset(previousFormState, {
          keepErrors: false,
          keepDirty: false,
          keepDirtyValues: false,
          keepValues: false,
          keepDefaultValues: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false
        });
      }
      setSupplierState((prevState) => ({ ...prevState, activeStep: value }));
    },
    [previousFormState, activeStep]
  );

  const updateFormState = (param: ISupplier) => {
    const defaultValues = getSupplierDetailFormValuesFromResponse(param, filterOptions);
    setSupplierState((prevState) => ({
      ...prevState,
      activeStep: 1,
      showVendorId: true,
      previousFormState: defaultValues,
      supplierInfo: param
    }));
  };

  const handleFirstSubmit: SubmitHandler<ISupplierDetailForm> = async (param) => {
    if (!previousFormState) {
      const requestBody = getRequestBodyForSupplierDetail(param);
      const response = await createSupplier(requestBody);
      if (response.success) {
        const { data } = response;
        updateFormState(data);
        setSupplierState((prevState) => ({
          ...prevState,
          newlyCreatedSupplierId: response.data.supplier_id,
          supplierInfo: data
        }));
        notify({ message: 'New Supplier has been added successfully!', dismissible: true });
      } else {
        const { error } = response;
        if (error.gst_number)
          firstSetError('gst_number', { message: 'Invalid GST Number' }, { shouldFocus: true });
        if (error.supplier_phone_number)
          firstSetError(
            'supplier_phone_number',
            { message: 'Enter valid Phone number' },
            { shouldFocus: true }
          );
        if (error.supplier_landline)
          firstSetError(
            'supplier_landline',
            { message: 'Enter valid Landline number' },
            { shouldFocus: true }
          );
        notify({
          message: error ?? 'Please try again with valid data',
          title: 'Form validation failed',
          severity: 'error',
          dismissible: true
        });
      }
    } else {
      const requestBody = getRequestBodyForSupplierDetail(param);
      const response = await updateSupplierInfo(previousFormState.supplier_id, requestBody);
      if (response.success) {
        const { data } = response;
        updateFormState(data);
        notify({ message: 'Supplier info been updated successfully!', dismissible: true });
      } else if (response.error) {
        const { error } = response;
        if (error.gst_number)
          firstSetError('gst_number', { message: 'Invalid GST Number' }, { shouldFocus: true });
        if (error.supplier_phone_number)
          firstSetError(
            'supplier_phone_number',
            { message: 'Enter valid Phone number' },
            { shouldFocus: true }
          );
        if (error.supplier_landline)
          firstSetError(
            'supplier_landline',
            { message: 'Enter valid Landline number' },
            { shouldFocus: true }
          );
        notify({
          message: 'Please try again with valid data',
          title: 'Form validation failed',
          severity: 'error',
          dismissible: true
        });
      }
    }
  };

  const handleNextClick = useCallback(() => {
    setSupplierState((prevState) => ({ ...prevState, activeStep: 1 }));
  }, []);

  const handleSecondSubmit: SubmitHandler<IBankDetailForm> = async (data) => {
    if (previousFormState) {
      const reqBody = getRequestBodyForSupplierBankDetail(data);
      const response = await updateSupplierInfo(previousFormState.supplier_id, reqBody);
      if (response.success) {
        notify({ message: 'Bank details have been added successfully!', dismissible: true });
        navigate(`/${CLIENT_ROUTES.supplier}/${response.data.supplier_id}`);
      } else
        notify({
          message: 'Please try again with valid data',
          title: 'Form validation failed',
          severity: 'error',
          dismissible: true
        });
    }
  };

  const handleBackClick = useCallback(() => {
    handleSidebarNavigation(0);
  }, [handleSidebarNavigation]);

  const handleCancelClick = useCallback(() => {
    if (newlyCreatedSupplierId) {
      navigate(`/${CLIENT_ROUTES.supplier}/${newlyCreatedSupplierId}`);
    } else {
      navigate(`/${CLIENT_ROUTES.supplier}`);
    }
  }, [newlyCreatedSupplierId]);

  const fetchUsersForPocList = async () => {
    const userList = await fetchAllUsers({
      role: 'external',
      unassigned: true
    });
    if (userList?.success) {
      setSupplierState((prevState: any) => ({ ...prevState, userList: userList.data.users }));
    }
  };

  const fetchFilterOptions = async () => {
    const response = await getSupplierFilterOptions();
    if (response.success) {
      setSupplierState((prevState: any) => ({ ...prevState, filterOptions: response.data }));
    } else {
      notify({
        severity: 'error',
        message: 'Failed to fetch filter options'
      });
    }
  };

  return (
    <AccessWrapper show={actions?.create} showUnauthorised>
      <main className={css.addSupplierWrapper}>
        <Seo title="Add Supplier" />
        <div className={css.supplierLabelWrapper}>
          <Typography variant="h2">Add Supplier </Typography>
          <Typography variant="subheading1">0{activeStep + 1} of 02</Typography>
        </div>
        <div className={css.addSupplierFormWrapper}>
          <div className={css.sidebarWrapper}>
            <SideBar
              activeStep={activeStep}
              onClick={handleSidebarNavigation}
              onEnter={handleSidebarNavigation}>
              <SideBar.Item label="Supplier Details" value={0} />
              <SideBar.Item label="Bank Details" value={1} disabled={!previousFormState} />
            </SideBar>
          </div>
          <FormProvider {...firstFormMethods}>
            {activeStep === 0 && (
              <SupplierDetailForm
                showVendorId={showVendorId}
                onFormSubmit={handleFirstSubmit}
                onNextClick={handleNextClick}
                onCancelClick={handleCancelClick}
                userList={userList}
                filterOptions={filterOptions}
              />
            )}
          </FormProvider>
          <FormProvider {...secondFormMethods}>
            {activeStep === 1 && (
              <BankDetailForm
                onFormSubmit={handleSecondSubmit}
                onBackClick={handleBackClick}
                onCancelClick={handleCancelClick}
                supplierData={supplierState.supplierInfo}
              />
            )}
          </FormProvider>
        </div>
        <Loader open={firstIsSubmitting || secondIsSubmitting} />
      </main>
    </AccessWrapper>
  );
};

export default SupplierAdd;
