import httpClient, { httpClient2 } from './index.service';
import { SERVER_CONFIG } from '@helpers/constants';

export async function fetchPaymentList(params?: any) {
  let paramQuery;
  if (params && Object.keys(params)?.length) {
    paramQuery = Object.keys(params).map((key) => {
      if (params[key]) {
        return `${key}=${params[key]}`;
      }
    });
  }
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.paymentList}?${paramQuery?.join('&')}`);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function fetchInvoiceList(params?: any) {
  let paramQuery = '';
  if (params && Object.keys(params)?.length) {
    paramQuery = Object.keys(params)
      .map((key) => {
        if (params[key]) return `${key}=${params[key]}`;
      })
      .join('&');
  }
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.invoiceList}?${paramQuery}`);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function paymentAlert(id: string | null) {
  if (!id) return null;
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.payments}payment/${id}/${SERVER_CONFIG.paymentAlert}/`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function supplierFilterOptions(params?: any) {
  let paramQuery = '';
  if (params && Object.keys(params)?.length) {
    paramQuery = Object.keys(params)
      .map((key) => {
        return `${key}=${params[key]}`;
      })
      .join('&');
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.formFilterOptions.supplierName}?${paramQuery}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function invoiceFilterOptions(params?: any) {
  let paramQuery = '';
  if (params && Object.keys(params)?.length) {
    paramQuery = Object.keys(params)
      .filter((key) => params[key] != null)
      .map((key) => {
        return `${key}=${encodeURIComponent(params[key])}`;
      })
      .join('&');
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.formFilterOptions.invoice}?${paramQuery}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function invoicePayment(invoice_info_id: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.payments}${SERVER_CONFIG.invoice}${invoice_info_id}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function requestPayment(invoice_info_id: string, requestBody: any) {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.payments}${SERVER_CONFIG.invoice}${invoice_info_id}/${SERVER_CONFIG.requestPayment}`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getMakePaymentInvoiceData(payment_id?: string[]) {
  try {
    const idQuery = payment_id ? payment_id.map((id) => `id=${id}`).join('&') : '';
    const response = await httpClient.get(
      `${SERVER_CONFIG.payments}${SERVER_CONFIG.initiated}?${idQuery}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export const intiatePayment = (reqBody: any) => {
  try {
    const response = httpClient.post(
      `${SERVER_CONFIG.payments}${SERVER_CONFIG.initiatePayment}`,
      reqBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export async function getUploadInvoiceFilterOptions(params?: any, order_ids?: any) {
  let paramQuery = '';
  if (params && Object.keys(params)?.length) {
    paramQuery = Object.keys(params)
      .map((key) => {
        if (params[key]) return `${key}=${params[key]}`;
      })
      .join('&');
  }
  if (order_ids?.length) {
    order_ids?.forEach((order: any) => {
      if (order?.order_id && order?.order_id?.length) paramQuery += `&order_ids=${order?.order_id}`;
    });
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.formFilterOptions.uploadInvoice}?${paramQuery}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function uploadInvoice(body: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.invoiceList}`, body);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function completePayment(body: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.completePayment}`, body);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getPaymentBulkSheet(cartId: string) {
  try {
    const response = await httpClient2.get(
      `${SERVER_CONFIG.payments}cart/${cartId}/${SERVER_CONFIG.bulkPaymentSheetDownload}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}
