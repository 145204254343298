import { memo, useMemo, Fragment } from 'react';
import css from './index.module.scss';
import { Typography } from '@components/base';
import { BoxContainer } from '@components/common';
import { IAddress } from '@helpers/types/address';

interface UserInfoProps {
  vendorId: string;
  gstNumber: string;
  phoneNumber: string;
  landlineNumber: string;
  email: string;
  website: string;
  addresses: IAddress[];
  businessType: string;
  originCountry: string;
  type: string;
}

const UserInfo = (props: UserInfoProps) => {
  const {
    vendorId,
    gstNumber,
    phoneNumber,
    landlineNumber,
    website,
    email,
    addresses,
    businessType,
    originCountry,
    type
  } = props;

  const officeAddress = useMemo(() => {
    const address = addresses.find((address) => address.address_type === 'OFC');
    if (address) return address.readable_address;
    return 'Unknown address';
  }, [props.addresses]);

  const factoryAddresses = useMemo(() => {
    const { addresses } = props;
    const facAddresses: string[] = [];
    addresses.forEach((address) => {
      if (address.address_type !== 'OFC') {
        facAddresses.push(address.readable_address);
      }
    });
    return facAddresses;
  }, [props.addresses]);

  return (
    <div className={css.userInfoContainer}>
      <BoxContainer title="Supplier Details">
        <div className={css.detailBoxWrapper}>
          <div className={css.rowWrapper}>
            <div className={css.textColumnWrapper}>
              <Typography variant="subheading1">Vendor ID</Typography>
              <Typography variant="p">{vendorId}</Typography>
            </div>
            <div className={css.textColumnWrapper}>
              <Typography variant="subheading1">GST Number</Typography>
              <Typography variant="p">{gstNumber}</Typography>
            </div>
            <div className={css.textColumnWrapper}>
              <Typography variant="subheading1">Email</Typography>
              <a
                href={`mailto:${email}`}
                className={css.linkLabel}
                target="_blank"
                rel="noopener noreferrer">
                {email}
              </a>
            </div>
          </div>
          <div className={css.rowWrapper}>
            <div className={css.textColumnWrapper}>
              <Typography variant="subheading1">Business Type</Typography>
              <Typography variant="p">{businessType}</Typography>
            </div>
            <div className={css.textColumnWrapper}>
              <Typography variant="subheading1">Origin Country</Typography>
              <Typography variant="p">{originCountry}</Typography>
            </div>
            <div className={css.textColumnWrapper}>
              <Typography variant="subheading1">Type</Typography>
              <Typography variant="p">{type}</Typography>
            </div>
          </div>
          <div className={css.rowWrapper}>
            <div className={css.textColumnWrapper}>
              <Typography variant="subheading1">Mobile</Typography>
              <a
                href={`tel:${phoneNumber}`}
                className={css.linkLabel}
                target="_blank"
                rel="noopener noreferrer">
                {phoneNumber?.length ? phoneNumber : '-'}
              </a>
            </div>
            <div className={css.textColumnWrapper}>
              <Typography variant="subheading1">Phone</Typography>
              <a
                href={`tel:${landlineNumber}`}
                className={css.linkLabel}
                target="_blank"
                rel="noopener noreferrer">
                {landlineNumber?.length ? landlineNumber : '-'}
              </a>
            </div>
            {website && (
              <div className={css.textColumnWrapper}>
                <Typography variant="subheading1">Website</Typography>
                <a
                  href={website}
                  className={css.linkLabel}
                  target="_blank"
                  rel="noopener noreferrer">
                  {website}
                </a>
              </div>
            )}
          </div>
          <div className={css.addressWrapper}>
            <Typography variant="subheading1">Office Address</Typography>
            <a
              href={`http://maps.google.com/?q=${officeAddress}`}
              className={css.linkLabel}
              target="_blank"
              rel="noopener noreferrer">
              {officeAddress}
            </a>
          </div>
          {factoryAddresses.map((address, index) => (
            <Fragment key={index}>
              {address && (
                <div className={css.addressWrapper}>
                  <Typography variant="subheading1">
                    {index <= 1 ? `Factory Address  ${index + 1}` : `Other`}
                  </Typography>
                  <a
                    href={`http://maps.google.com/?q=${address}`}
                    className={css.linkLabel}
                    target="_blank"
                    rel="noopener noreferrer">
                    {address}
                  </a>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </BoxContainer>
    </div>
  );
};

export default memo(UserInfo);
