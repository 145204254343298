import { Fragment, useEffect, useRef, useState } from 'react';
import css from './index.module.scss';
import { Button, CheckboxLabel, IconNode, Typography } from '@components/base';
import { AutoComplete, InputDatePicker, SelectLabel } from '@components/common';
import { ISupplier } from '@helpers/types/supplier';
import { getSupplierList } from '@services/supplier.service';
import { fetchAllProducts } from '@services/product.service';
import notify from '@helpers/toastify-helper';
import { ProductInfo } from '@helpers/types/product';
import { useForm, Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { ISelect } from '@helpers/types';
import { fetchAllCustomers } from '@services/customer.service';
import { fetchAllUsers } from '@services/user.service';
import { INCO_TERMS } from '@helpers/constants';
import Images from '@assets/images';
import { ICustomerInfo } from '@helpers/types/customer';
import { fetchEntityList } from '@services/order.service';
import { SelfEntity } from '@helpers/types/entity';

interface FilterDrawerProps {
  onClose?: () => void;
  onApply?: (data: FormType) => void;
  value?: FormType | null;
}

interface orderPOC {
  user_id: string;
  given_name: string;
  family_name: string;
}

interface FilterDrawerState {
  supplierList: ISupplier[];
  suppliersNameList: any;
  productList: ProductInfo[];
  orderPOCList: orderPOC[];
  entityList: SelfEntity[];
  customerList: ICustomerInfo[];
  isLoading: boolean;
  isOpen: boolean;
}

export interface FormType {
  supplier: ISupplier[];
  product: ProductInfo[];
  customer: ICustomerInfo[];
  country: ISelect[];
  entity: SelfEntity | null;
  supplier_type_indian: boolean;
  order_type: ISelect | null;
  supplier_type_foreign: boolean;
  business_type_trader: boolean;
  business_type_manufacturer: boolean;
  inco_terms_type: ISelect[];
  selectedOrderPOC: orderPOC[];
  startBLAWBDate: Date | null;
  endBLAWBDate: Date | null;
  startOrderDate: Date | null;
  endOrderDate: Date | null;
  isCancelled: boolean;
  excludeCancelled: boolean;
  isCompleted: boolean;
}

const FilterDrawer = (props: FilterDrawerProps) => {
  const { onClose, value } = props;
  const [initialSupplier, setInitialSupplier] = useState([]);
  const [initialCustomer, setInitialCustomer] = useState([]);
  const [initialProduct, setInitialProduct] = useState([]);
  const [initialOrderPOC, setInitialOrderPOC] = useState([]);

  const [drawerState, setDrawerState] = useState<FilterDrawerState>({
    supplierList: [],
    suppliersNameList: [],
    productList: [],
    orderPOCList: [],
    customerList: [],
    entityList: [],
    isLoading: false,
    isOpen: false
  });
  const debounceRef = useRef<any>(null);

  const {
    supplierList,
    suppliersNameList,
    productList,
    customerList,
    orderPOCList,
    isLoading,
    entityList
  } = drawerState;

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty }
  } = useForm<FormType>({
    defaultValues: {
      supplier: [],
      product: [],
      country: [],
      customer: [],
      selectedOrderPOC: [],
      entity: null,
      supplier_type_indian: false,
      supplier_type_foreign: false,
      business_type_trader: false,
      business_type_manufacturer: false,
      startBLAWBDate: null,
      endBLAWBDate: null,
      startOrderDate: null,
      endOrderDate: null,
      order_type: null,
      inco_terms_type: [],
      isCancelled: false,
      excludeCancelled: false,
      isCompleted: false
    }
  });

  useEffect(() => {
    const { value } = props;
    if (value) {
      const transformedValue = {
        supplier: value.supplier || [],
        product: value.product || [],
        customer: value.customer || [],
        selectedOrderPOC: value.selectedOrderPOC || [],
        order_type: value.order_type || null,
        inco_terms_type: value.inco_terms_type || [],
        startOrderDate: value.startOrderDate ? new Date(value.startOrderDate) : null,
        endOrderDate: value.endOrderDate ? new Date(value.endOrderDate) : null,
        startBLAWBDate: value.startBLAWBDate ? new Date(value.startBLAWBDate) : null,
        endBLAWBDate: value.endBLAWBDate ? new Date(value.endBLAWBDate) : null,
        isCancelled: value.isCancelled || false,
        excludeCancelled: value.excludeCancelled || false,
        entity: value.entity || null,
        isCompleted: value.isCompleted || false
      };

      reset(transformedValue, { keepDirty: false });
    }
    fetchSuppliersList();
    fetchCustomersList();
    fetchProductsList();
    fetchOrderPOCList();
    fetchEntitiesList();
  }, []);

  const fetchSuppliersList = async () => {
    setDrawerState((prevState) => ({ ...prevState, isLoading: true }));
    const supplierResponse = await getSupplierList();
    if (supplierResponse.success) {
      const { data: supplier_list } = supplierResponse;
      setDrawerState((prevState) => ({
        ...prevState,
        supplierList: supplier_list.results,
        isLoading: false
      }));
      setInitialSupplier(supplier_list.results);
    } else {
      setDrawerState((prevState) => ({ ...prevState, isLoading: false }));
      notify({ message: 'Unable to fetch supplier list', severity: 'error' });
    }
  };

  const fetchCustomersList = async () => {
    setDrawerState((prevState) => ({ ...prevState, isLoading: true }));
    const customerResponse = await fetchAllCustomers();
    if (customerResponse.success) {
      const { data: customer_list } = customerResponse;
      setDrawerState((prevState) => ({
        ...prevState,
        customerList: customer_list.results,
        isLoading: false
      }));
      setInitialCustomer(customer_list.results);
    } else {
      setDrawerState((prevState) => ({ ...prevState, isLoading: false }));
      notify({ message: 'Unable to fetch customer list', severity: 'error' });
    }
  };

  const fetchProductsList = async () => {
    setDrawerState((prevState) => ({ ...prevState, isLoading: true }));
    const productResponse = await fetchAllProducts();
    if (productResponse.success) {
      const { data: product_list } = productResponse;
      setDrawerState((prevState) => ({
        ...prevState,
        productList: product_list.results,
        isLoading: false
      }));
      setInitialProduct(product_list.results);
    } else {
      setDrawerState((prevState) => ({ ...prevState, isLoading: false }));
      notify({ message: 'Unable to fetch product list', severity: 'error' });
    }
  };

  const fetchOrderPOCList = async () => {
    setDrawerState((prevState) => ({ ...prevState, isLoading: true }));
    const userResponse = await fetchAllUsers({ role: 'internal' });
    if (userResponse.success) {
      const { data: user_list } = userResponse;
      setDrawerState((prevState) => ({
        ...prevState,
        orderPOCList: user_list.users,
        isLoading: false
      }));
      setInitialOrderPOC(user_list.users);
    } else {
      setDrawerState((prevState) => ({ ...prevState, isLoading: false }));
      notify({ message: 'Unable to fetch order POC list', severity: 'error' });
    }
  };

  const fetchEntitiesList = async () => {
    setDrawerState((prevState) => ({ ...prevState, isLoading: true }));
    const entityList = await fetchEntityList();
    if (entityList.success) {
      const { data: entity_list } = entityList;
      setDrawerState((prevState) => ({
        ...prevState,
        entityList: entity_list.results,
        isLoading: false
      }));
    } else {
      setDrawerState((prevState) => ({ ...prevState, isLoading: false }));
      notify({ message: 'Unable to fetch entity list', severity: 'error' });
    }
  };

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
    const { onApply } = props;
    onApply?.(data);
  };

  const filterSupplier = async (event: any, param: string) => {
    if (param) {
      const supplierResponse = await getSupplierList({ supplier_name: param });
      if (supplierResponse.success) {
        const { data: supplier_list } = supplierResponse;
        setDrawerState((prevState) => ({
          ...prevState,
          supplierList: supplier_list.results,
          isLoading: false
        }));
      } else {
        setDrawerState((prevState) => ({ ...prevState, isLoading: false }));
        notify({ message: 'Unable to fetch supplier list', severity: 'error' });
      }
    } else {
      setDrawerState((prevState) => ({ ...prevState, supplierList: initialSupplier }));
    }
  };

  const filterCustomer = async (event: any, param: string) => {
    if (param) {
      const customerResponse = await fetchAllCustomers(1, param);
      if (customerResponse.success) {
        const { data: customer_list } = customerResponse;
        setDrawerState((prevState) => ({
          ...prevState,
          customerList: customer_list.results,
          isLoading: false
        }));
      } else {
        setDrawerState((prevState) => ({ ...prevState, isLoading: false }));
        notify({ message: 'Unable to fetch customer list', severity: 'error' });
      }
    } else {
      setDrawerState((prevState) => ({ ...prevState, customerList: initialCustomer }));
    }
  };

  const filterProduct = async (event: any, param: string) => {
    if (param) {
      const productResponse = await fetchAllProducts(1, param);
      if (productResponse.success) {
        const { data: product_list } = productResponse;
        setDrawerState((prevState) => ({
          ...prevState,
          productList: product_list.results,
          isLoading: false
        }));
      } else {
        setDrawerState((prevState) => ({ ...prevState, isLoading: false }));
        notify({ message: 'Unable to fetch product list', severity: 'error' });
      }
    } else {
      setDrawerState((prevState) => ({ ...prevState, productList: initialProduct }));
    }
  };

  const filterOrderPOC = async (event: any, param: string) => {
    if (param) {
      const userResponse = await fetchAllUsers({ role: 'internal', name: param });
      if (userResponse.success) {
        const { data: user_list } = userResponse;
        setDrawerState((prevState) => ({
          ...prevState,
          orderPOCList: user_list.users,
          isLoading: false
        }));
      } else {
        setDrawerState((prevState) => ({ ...prevState, isLoading: false }));
        notify({ message: 'Unable to fetch order POC list', severity: 'error' });
      }
    } else {
      setDrawerState((prevState) => ({ ...prevState, orderPOCList: initialOrderPOC }));
    }
  };

  const orderTypeOptions = [
    { label: 'MTT', value: 'MTT' },
    { label: 'Export from India', value: 'IND' }
  ];

  const onReset = () => {
    const defaultValues = {
      supplier: [],
      product: [],
      customer: [],
      selectedOrderPOC: [],
      order_type: null,
      entity: null,
      inco_terms_type: [],
      startOrderDate: null,
      endOrderDate: null,
      startBLAWBDate: null,
      endBLAWBDate: null,
      isCancelled: false,
      excludeCancelled: false,
      isCompleted: false
    };
    reset(defaultValues, { keepDirty: false });
  };

  return (
    <Fragment>
      <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h3">Filter</Typography>
          <div className={css.actionWrapper}>
            <Button type="submit">Apply Filters</Button>
            <Button variant="outlined-secondary" onClick={onReset}>
              Reset
            </Button>
            <IconNode src={Images.close} alt="filter icon" onClick={onClose} />
          </div>
        </div>
        <div className={css.mainSection}>
          <div className={css.autoCompleteWrapper}>
            <Controller
              name="supplier"
              control={control}
              render={({ field, fieldState }) => {
                const onSupplierSelection = (event: any, param: any) => {
                  if (param) {
                    setValue('supplier', param);
                  } else {
                    setValue('supplier', []);
                  }
                };
                return (
                  <AutoComplete
                    {...field}
                    label="Supplier"
                    multiple
                    options={supplierList}
                    onInputChange={(event: any, param: string) => filterSupplier(event, param)}
                    onInputSelection={onSupplierSelection}
                    keyOption="supplier_name"
                    getOptionValue={(option: ISupplier) => option.supplier_id}
                    onSelect={(option: any) => {
                      field.onChange(option);
                    }}
                    placeholder="Search Supplier"
                    width="auto"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
            <Controller
              name="customer"
              control={control}
              render={({ field, fieldState }) => {
                const onCustomerSelection = (event: any, param: any) => {
                  if (param) {
                    setValue('customer', param);
                  } else {
                    setValue('customer', []);
                  }
                };
                return (
                  <AutoComplete
                    {...field}
                    label="Customer"
                    multiple
                    options={customerList}
                    onInputChange={(event: any, param: string) => filterCustomer(event, param)}
                    onInputSelection={onCustomerSelection}
                    keyOption="name"
                    getOptionValue={(option: ICustomerInfo) => option.customer_id}
                    onSelect={(option: any) => {
                      field.onChange(option);
                    }}
                    placeholder="Search Customer"
                    width="auto"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
            <Controller
              name="product"
              control={control}
              render={({ field, fieldState }) => {
                const onProductSelection = (event: any, param: any) => {
                  if (param) {
                    setValue('product', param);
                  } else {
                    setValue('product', []);
                  }
                };
                return (
                  <AutoComplete
                    {...field}
                    label="Products"
                    multiple
                    options={productList}
                    onInputChange={(event: any, param: string) => filterProduct(event, param)}
                    onInputSelection={onProductSelection}
                    keyOption="name"
                    getOptionValue={(option: ProductInfo) => option.product_id}
                    onSelect={(option: any) => {
                      field.onChange(option);
                    }}
                    placeholder="Search Products"
                    width="auto"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
            <Controller
              name="selectedOrderPOC"
              control={control}
              render={({ field, fieldState }) => {
                const onOrderPOCSelection = (event: any, param: any) => {
                  if (param) {
                    setValue('selectedOrderPOC', param);
                  } else {
                    setValue('selectedOrderPOC', []);
                  }
                };
                return (
                  <AutoComplete
                    {...field}
                    label="Order POC"
                    multiple
                    options={orderPOCList}
                    onInputChange={(event: any, param: string) => filterOrderPOC(event, param)}
                    onInputSelection={onOrderPOCSelection}
                    keyOption="given_name"
                    labelKeys={['given_name', 'family_name']}
                    getOptionValue={(option: orderPOC) => option.user_id}
                    onSelect={(option: any) => {
                      field.onChange(option);
                    }}
                    placeholder="Search Order POC"
                    width="auto"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </div>
          <Controller
            name="order_type"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isClearable
                isSearchable
                placeholder="Select Order Type"
                isLoading={isLoading}
                label="Order Type"
                options={orderTypeOptions}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Controller
            name="entity"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isClearable
                placeholder="Select Entity Type"
                isLoading={isLoading}
                label="Entity Type"
                options={entityList}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.entity_id}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Controller
            name="inco_terms_type"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                isClearable
                isLoading={isLoading}
                label="INCO Terms Type"
                options={INCO_TERMS}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <div className={css.orderWrapper}>
            <Controller
              name={`startOrderDate`}
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label="Order Start Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.dateWrapper}
                />
              )}
            />
            <Controller
              name={`endOrderDate`}
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label="Order End Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.dateWrapper}
                />
              )}
            />
          </div>
          <div className={css.orderWrapper}>
            <Controller
              name={`startBLAWBDate`}
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label="Start BL/AWB Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.dateWrapper}
                />
              )}
            />
            <Controller
              name={`endBLAWBDate`}
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label="End BL/AWB Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.dateWrapper}
                />
              )}
            />
          </div>
          <div className={css.booleanWrapper}>
            <Controller
              name={`excludeCancelled`}
              control={control}
              render={({ field }) => (
                <CheckboxLabel
                  {...field}
                  label="Exclude Cancelled Orders"
                  value={`${field.value}`}
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    if (e.target.checked) {
                      setValue('isCancelled', false);
                    }
                  }}
                />
              )}
            />
            <Controller
              name={`isCancelled`}
              control={control}
              render={({ field }) => (
                <CheckboxLabel
                  {...field}
                  label="Show Cancelled Orders"
                  value={`${field.value}`}
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    if (e.target.checked) {
                      setValue('excludeCancelled', false);
                    }
                  }}
                />
              )}
            />

            {/* <Controller
              name={`isCompleted`}
              control={control}
              render={({ field }) => (
                <CheckboxLabel
                  {...field}
                  label="Completed Orders"
                  value={`${field.value}`}
                  checked={field.value}
                />
              )}
            /> */}
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default FilterDrawer;
