import { memo, useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import { Button, IconNode, Typography } from '@components/base';
import {
  CancelOrder,
  MultiplePlaceholder,
  LinkButton,
  EstimateDatesModal
} from '@components/common';
import Images from '@assets/images';
import { CLIENT_ROUTES } from '@router/routes';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getFormattedDate, sanitizeValue } from '@helpers/utils';
import { cancelOrderSchema } from '@helpers/yup/add-order.schema';
import { OrderInfo } from '@helpers/types/order';
import AccessWrapper from '@authorization/access-wrapper';
import StepProgressBar from '@components/common/progress-bar/progress-bar-step';
import moment from 'moment';
import NotesCreateEditModal from '@components/common/notes-createEdit';
import { useNavigate } from 'react-router-dom';
import { updateTaskEstimate } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import NotesDetailModal from './notes-detail';

interface OrderCardProps {
  orderInfo: OrderInfo;
  actions?: IActions;
  notesActions?: IActions;
  refreshOrderList: () => void;
}

interface ProgressBardState {
  labelList: any[];
  stepLabels: number[][];
  isCurrent: number;
}

const OrderCardInternal = (props: OrderCardProps) => {
  const { orderInfo, actions, notesActions, refreshOrderList } = props;

  const { currency } = orderInfo;
  const [cancelOrderModal, setCancelOrderModal] = useState(false);
  const [progressBarState, setProgressBarState] = useState<ProgressBardState>({
    labelList: [],
    stepLabels: [],
    isCurrent: 0
  });
  const [openDetailView, setOpenDetailView] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openNotesDetailtModal, setOpenNotesDetailtModal] = useState(false);
  const [openEstimatesModal, setOpenEstimatesModal] = useState(false);

  const { labelList, stepLabels, isCurrent } = progressBarState;

  const navigate = useNavigate();

  const orderCancelForm = useForm<any>({
    resolver: yupResolver(cancelOrderSchema),
    defaultValues: {
      cancel_remarks: null
    },
    shouldUnregister: true
  });

  const OrderCompletedObj = useMemo(() => {
    return orderInfo?.customer_state_activity_log?.find(
      (item: any) => item?.state_name == 'Order Completed'
    );
  }, [orderInfo]);

  const cancelStatus = useMemo(() => {
    return {
      is_cancelled: orderInfo?.is_cancelled,
      cancel_remarks: orderInfo?.cancel_remarks,
      last_updated_by: orderInfo?.last_updated_by,
      updated_at: getFormattedDate(orderInfo?.updated_at)
    };
  }, [orderInfo]);

  useEffect(() => {
    if (orderInfo?.customer_state_activity_log) {
      const labels = orderInfo?.customer_state_activity_log.map((item: any) => {
        return {
          label: item.state_name,
          date:
            getFormattedDate(
              item.actual_date ? item.actual_date : item.estimated_date ? item.estimated_date : ''
            ) ?? ''
        };
      });
      const isCurrentState = orderInfo?.is_order_completed
        ? Number.MAX_VALUE
        : orderInfo?.customer_state_activity_log.findIndex(
            (item: any) => item.is_current_state === true
          );
      setProgressBarState({
        labelList: labels,
        stepLabels: stepLabels,
        isCurrent: isCurrentState
      });
    }
  }, [orderInfo]);

  const openCustomer = () => {
    window.open(`/customer/${orderInfo.customer?.customer_id}`, '_blank');
  };

  const orderTAT = useMemo(() => {
    const startDate = moment(
      orderInfo?.customer_state_activity_log?.[0]?.actual_date ??
        orderInfo?.customer_state_activity_log?.[0]?.estimated_date ??
        0
    );
    const endDate = moment(
      orderInfo?.customer_state_activity_log?.[orderInfo?.customer_state_activity_log?.length - 1]
        ?.actual_date ??
        orderInfo?.customer_state_activity_log?.[orderInfo?.customer_state_activity_log?.length - 1]
          ?.estimated_date ??
        0
    );

    const duration = endDate.diff(startDate, 'days');
    return duration;
  }, [orderInfo]);

  const onEstimateFormSubmit = async (data: any) => {
    if (data?.estimate_data?.length) {
      const body = data?.estimate_data.map((item: any) => {
        return {
          state_type: item.state_type,
          estimated_date: item.estimated_date
            ? moment(item.estimated_date).format('YYYY-MM-DD')
            : null,
          id: item.id
        };
      });

      const response = await updateTaskEstimate(orderInfo?.order_id ?? '', body);
      if (response.success) {
        notify({
          message: 'Task Estimates added successfully'
        });
        setOpenEstimatesModal(false);
        refreshOrderList();
      } else {
        notify({ message: response.error ?? 'Something went wrong!', severity: 'error' });
      }
    }
  };

  return (
    <>
      <div className={css.mainWrapper}>
        <div>
          {cancelStatus.is_cancelled ? (
            <div className={css.cancelWrapper}>
              <div className={css.columnWrapper}>
                <Typography variant="p" className={css.cancelOrderTitle}>
                  This order has been cancelled by {cancelStatus.last_updated_by ?? 'Unknown User'}{' '}
                  on {cancelStatus.updated_at}
                </Typography>
                <Typography variant="p">
                  {cancelStatus.cancel_remarks ?? 'No Cancel Remarks'}
                </Typography>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className={`${css.columnWrapper} ${cancelStatus.is_cancelled ? css.disable : ``}`}>
            <div className={css.headerWrapper}>
              <Typography variant="span" className={css.orderId}>
                #{orderInfo?.readable_order_id}
              </Typography>
              <Typography variant="h3" onClick={openCustomer} className={css.hoverText}>
                {orderInfo?.customer?.name}
              </Typography>
              <div className={css.sideWrapper}>
                {!cancelStatus.is_cancelled ? (
                  <>
                    <div className={css.buttonWrapper}>
                      <div className={css.itemWrapper}>
                        <div className={css.detailWrapper}>
                          <Typography variant="ptext">Expected Delivery</Typography>
                          <div className={css.iconContainer}>
                            <Typography variant="body">
                              {sanitizeValue(
                                getFormattedDate(
                                  OrderCompletedObj?.actual_date ??
                                    OrderCompletedObj?.estimated_date
                                ),
                                'N.A.'
                              )}
                            </Typography>
                            {orderInfo?.estimate_date_edit ? (
                              <IconNode
                                src={Images.editRed}
                                className={css.editIcon}
                                alt="edit"
                                title="Edit"
                                onClick={() => setOpenEstimatesModal(true)}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <AccessWrapper show={notesActions?.create ?? false}>
                      <div className={css.buttonWrapper}>
                        <Button
                          variant="text"
                          onClick={() => setOpenNotesDetailtModal(true)}
                          startIcon={<IconNode src={Images.notes} />}>
                          Notes
                        </Button>
                      </div>
                    </AccessWrapper>

                    <AccessWrapper show={actions?.delete || false}>
                      <div className={css.buttonWrapper}>
                        {cancelStatus.is_cancelled ? (
                          <></>
                        ) : (
                          <div
                            className="link-button-text-secondary"
                            onClick={() => setCancelOrderModal(true)}>
                            Cancel Order
                          </div>
                        )}
                      </div>
                    </AccessWrapper>
                  </>
                ) : (
                  <></>
                )}

                <div className={css.buttonWrapper}>
                  <LinkButton
                    variant="outlined-secondary"
                    to={`/${CLIENT_ROUTES.order}/${orderInfo?.order_id}`}
                    title="View Order"
                    eventTrackingName="ORDER_VIEW_CLICK">
                    View Order
                  </LinkButton>
                </div>
              </div>
              {!cancelStatus.is_cancelled ? (
                <>
                  <div className={css.arrowIconWrapper}>
                    {openDetailView ? (
                      <IconNode
                        onClick={() => setOpenDetailView(false)}
                        src={Images.accordianArrowUp}
                        className={css.arrowIcon}
                      />
                    ) : (
                      <IconNode
                        onClick={() => setOpenDetailView(true)}
                        src={Images.accordianArrowDown}
                        className={css.arrowIcon}
                      />
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          {!cancelStatus.is_cancelled ? (
            <>
              <div className={css.rowWrapper}>
                <div className={css.orderDetailWrapper}>
                  <div className={css.detailWrapper}>
                    <Typography variant="ptext">Item</Typography>
                    <Typography variant="body">
                      <MultiplePlaceholder
                        typographyVariant="body"
                        names={orderInfo?.order_item?.map((item: any) => item.product_name)}
                      />
                    </Typography>
                  </div>
                </div>
                <div className={css.orderDetailWrapper}>
                  <div className={css.detailWrapper}>
                    <Typography variant="ptext">Supplier</Typography>
                    <Typography variant="body">
                      {
                        <MultiplePlaceholder
                          typographyVariant="body"
                          names={orderInfo?.order_item
                            ?.filter((data) => data?.supplier_name !== null)
                            .map((data) => data.supplier_name)}
                        />
                      }
                    </Typography>
                  </div>
                </div>

                <div className={css.orderDetailWrapper}>
                  <div className={css.detailWrapper}>
                    <Typography variant="ptext">Payment Term</Typography>
                    <Typography variant="body">{orderInfo?.payment_terms_display_value}</Typography>
                  </div>
                </div>
                <div className={css.orderDetailWrapper}>
                  <div className={css.detailWrapper}>
                    {openDetailView ? (
                      <>
                        <Typography variant="ptext">Due Amount</Typography>
                        <Typography variant="body">
                          {currency} {orderInfo?.amount_due}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant="ptext">Due Amount/ Total Amount</Typography>
                        <Typography variant="body">
                          {currency} {orderInfo?.amount_due}/{currency} {orderInfo?.amount_due}
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
                <div className={css.orderDetailWrapper}>
                  <div className={css.detailWrapper}>
                    {openDetailView ? (
                      <>
                        <Typography variant="ptext">Total Amount</Typography>
                        <Typography variant="body">
                          {currency} {orderInfo?.total}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant="ptext">Created By</Typography>
                        <Typography variant="body">
                          {sanitizeValue(orderInfo?.created_by, 'Unknown User')}
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {openDetailView ? (
                <>
                  <div className={css.rowWrapper}>
                    <div className={css.orderDetailWrapper}>
                      <div className={css.detailWrapper}>
                        <Typography variant="ptext">Created By</Typography>
                        <Typography variant="body">
                          {sanitizeValue(orderInfo?.created_by)}
                        </Typography>
                      </div>
                    </div>
                    <div className={css.orderDetailWrapper}>
                      <div className={css.detailWrapper}>
                        <Typography variant="ptext">Purchase Rep</Typography>
                        <Typography variant="body">
                          <MultiplePlaceholder
                            names={orderInfo?.purchase_rep}
                            typographyVariant="body"
                          />
                        </Typography>
                      </div>
                    </div>

                    <div className={css.orderDetailWrapper}>
                      <div className={css.detailWrapper}>
                        <Typography variant="ptext">
                          {orderInfo?.transportation_mode_display_value == 'Sea' ? `BL` : `AWB`}{' '}
                          Date
                        </Typography>
                        <Typography variant="body">
                          {sanitizeValue(
                            getFormattedDate(
                              orderInfo?.transportation_mode_display_value == 'Sea'
                                ? orderInfo?.bl_date
                                : orderInfo?.awb_date
                            )
                          )}
                        </Typography>
                      </div>
                    </div>
                    <div className={css.orderDetailWrapper}>
                      <div className={css.detailWrapper}>
                        <Typography variant="ptext">BL Number</Typography>
                        <Typography variant="body">
                          {sanitizeValue(
                            orderInfo?.transportation_mode_display_value == 'Sea'
                              ? orderInfo?.bl_number
                              : orderInfo?.awb_number
                          )}
                        </Typography>
                      </div>
                    </div>
                    <div className={css.orderDetailWrapper}>
                      <div className={css.detailWrapper}>
                        <Typography variant="ptext">Order TAT</Typography>
                        <Typography variant="body">
                          {orderTAT} {orderTAT > 1 ? `Days` : `Day`}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className={css.rowWrapper}>
                    <div className={css.orderDetailWrapper}>
                      <div className={css.detailWrapper}>
                        <Typography variant="ptext">Place of Loading</Typography>
                        <Typography variant="body">
                          {orderInfo?.port_of_loading_display_value},{' '}
                          {orderInfo?.port_of_loading_country_display_value}
                        </Typography>
                      </div>
                    </div>
                    <div className={css.orderDetailWrapper}>
                      <div className={css.detailWrapper}>
                        <Typography variant="ptext">Place of Discharge</Typography>
                        <Typography variant="body">
                          {' '}
                          {orderInfo?.port_of_discharge_display_value},{' '}
                          {orderInfo?.port_of_discharge_country_display_value}
                        </Typography>
                      </div>
                    </div>

                    <div className={css.orderDetailWrapper}>
                      <div className={css.detailWrapper}>
                        <Typography variant="ptext">PI Number</Typography>
                        <Typography variant="body">
                          {sanitizeValue(orderInfo?.pi_document_no)}
                        </Typography>
                      </div>
                    </div>
                    <div className={css.orderDetailWrapper}>
                      <div className={css.detailWrapper}>
                        <Typography variant="ptext">CI Number</Typography>
                        <Typography variant="body">
                          {sanitizeValue(orderInfo?.ci_document_no)}
                        </Typography>
                      </div>
                    </div>
                    <div className={css.orderDetailWrapper}>
                      <div className={css.detailWrapper}>
                        <Typography variant="ptext">Pending Tasks</Typography>
                        <Typography variant="body">
                          <MultiplePlaceholder
                            names={orderInfo.pending_tasks}
                            typographyVariant="body"
                          />
                        </Typography>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className={css.rowWrapper}>
                <StepProgressBar label={labelList} currentStep={isCurrent} />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <FormProvider {...orderCancelForm}>
        <CancelOrder
          open={cancelOrderModal}
          onClose={() => setCancelOrderModal(false)}
          orderId={orderInfo?.order_id}
        />
      </FormProvider>
      {openNotesModal && (
        <NotesCreateEditModal
          open={openNotesModal}
          onClose={() => {
            navigate(`/order/${orderInfo?.order_id}?activeTab=Notes&activeSubTab=public&page=1`);
            setOpenNotesModal(false);
          }}
          orderId={orderInfo?.order_id}
          handleCancel={() => setOpenNotesModal(false)}
        />
      )}
      {openEstimatesModal && (
        <EstimateDatesModal
          open={openEstimatesModal}
          onClose={() => setOpenEstimatesModal(false)}
          taskData={{
            task_estimates: orderInfo?.task_estimates
          }}
          onFormSubmit={onEstimateFormSubmit}
        />
      )}
      {openNotesDetailtModal && (
        <NotesDetailModal
          open={openNotesDetailtModal}
          handleCancel={() => setOpenNotesDetailtModal(false)}
          data={orderInfo?.latest_note}
          orderId={orderInfo?.order_id}
          openAddNoteModal={() => setOpenNotesModal(true)}
        />
      )}
    </>
  );
};

export default memo(OrderCardInternal);
